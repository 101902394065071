<template>
  <div class="main">
    <div id="xgplayer" style="max-height: 966px"></div>
    <span class="count" style="visibility: hidden">{{count}}s</span>
  </div>
</template>

<script>
import Player from "xgplayer";
import HlsJsPlayer from "xgplayer-hls.js";
import { mapActions, mapState } from 'vuex';
export default {
  name: "SerialPage",
  data() {
    return {
      player: null,
      timer: null,
      count: 0,
    };
  },
  props: {
    videos: {
      type: Object, default: () => {
      }
    },
    url: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.gettimeUpdate()
  },
  computed: {
    ...mapState({
      moviesDetails: (state) => state.serial.moviesDetails,
    }),
  },
  methods: {
    ...mapActions({
       SetLoadStatusFn: "SetLoadStatusFn",
       SubmitTimeUpdateFn: "SubmitTimeUpdateFn",
       getTimeUpdateFn:"getTimeUpdateFn",
    }),
    init(videos,time) {
      let THIS = this
      this.timer = setInterval(() => {
        THIS.count++;
      }, 10)
      let locale = window.localStorage.getItem("locale") || 1,lang='';let preview= this.videos.preview_url[0] || '';
      if (locale==1){
        lang = 'en'
      }else if(locale==2){
        lang = 'zh-cn'
      } else{
        lang = 'jp'
      }
      let lastPlayTimes= time;
      this.$nextTick(() => {
        let textTrack = [];
        if(this.videos.subtitles && this.videos.subtitles.length ==1){
          textTrack=  this.videos.subtitles.map((item,index)=>{
            let obj= {
              src:  item.url,
              kind: "subtitles",
              label: item.name,
              srclang: lang,
              default: true
            }
            obj.default=index===0?true:false
            return obj
          })
        }
        if (this.appSource()=="ios" || this.device !='mob'){
          this.player = new HlsJsPlayer({
            id: "xgplayer",
            lang: lang,
            url: this.url,
            autoplay: true,
            autoplayMuted: true,
            fluid: true,
            fitVideoSize: 'fixWidth',
            Muted: true,
            loop: true,
            playbackRate: [0.5, 0.75, 1, 1.5, 2],
            ignores: ['replay'],
            width: '100vw',
            height: '100vw',
            volume: 0.6,
            poster: videos.cover,
            lastPlayTime: lastPlayTimes,
            pip: true,
            keyShortcut: 'on',
            keyShortcutStep: { //设置调整步长
              currentTime: 15, //播放进度调整步长，默认10秒
              volume: 0.2 //音量调整步长，默认0.1
            },
            'x5-video-player-type': 'h5', // 微信内置浏览器设置，防止被浏览器劫持
            'x5-video-orientation': 'landscape',
            'x5-video-player-fullscreen': true,
            // thumbnail: {
            //   pic_num: 44,
            //   width: 160,
            //   height: 90,
            //   col: 10,
            //   row: 10,
            //   urls: preview,
            //   isShowCoverPreview: false
            // },
            textTrack: textTrack,
            textTrackActive: 'click',
            closeVideoTouch: true,
            playsinline: true,
            whitelist: ['iPhone','Android'],
          });
          this.player.once('play', (res) => {
            clearInterval(THIS.timer);
            gtag("event", "ct_video_buffer", {
              ct_video_id: videos.id,
              ct_video_name: videos.name,
              ct_video_bufferdurtime: THIS.count/10,
            });
            setTimeout( async () => {
              this.SetLoadStatusFn( { loadStatus: false});
            },1000);
          });
        }else{
          this.player = new HlsJsPlayer({
            id: "xgplayer",
            lang: lang,
            url: this.url,
            autoplay: true,
            autoplayMuted: true,
            fluid: true,
            loop: true,
            fitVideoSize: 'fixWidth',
            playbackRate: [0.5, 0.75, 1, 1.5, 2],
            ignores: ['replay'],
            width: '100vw',
            height: '100vw',
            volume: 0.6,
            poster: videos.cover,
            pip: true,
            keyShortcut: 'on',
            keyShortcutStep: { //设置调整步长
              currentTime: 15, //播放进度调整步长，默认10秒
              volume: 0.2 //音量调整步长，默认0.1
            },
            'x5-video-player-type': 'h5', // 微信内置浏览器设置，防止被浏览器劫持
            'x5-video-orientation': 'portraint',
            // thumbnail: {
            //   pic_num: 44,
            //   width: 160,
            //   height: 90,
            //   col: 10,
            //   row: 10,
            //   urls: preview,
            //   isShowCoverPreview: false
            // },
            nativeTextTrack: textTrack,
            textTrackActive: 'click',
            closeVideoTouch: true,
            playsinline: true,
            whitelist: ['iPhone','Android'],
          });
          if (this.player) {
              let current_Time =this.player.currentTime
              let last_Time =this.player.lastPlayTime
              this.player.once('play', (res) => {
                setTimeout( async () => {
                  clearInterval(THIS.timer);
                  gtag("event", "ct_video_buffer", {
                    ct_video_id: videos.id,
                    ct_video_name: videos.name,
                    ct_video_bufferdurtime: THIS.count/10,
                  });
                  this.SetLoadStatusFn( { loadStatus: false});
                  res.currentTime = lastPlayTimes
                  current_Time = lastPlayTimes
                  last_Time  = lastPlayTimes
                },2500);
              });
          }
        }
        setTimeout( async () => {
          this.SetLoadStatusFn( { loadStatus: false});
        },6000);
        this.player.on('waiting', (res) => {
          this.times+=1
        });
        if(this.videos.subtitles && this.videos.subtitles.length >1){
          this.player.setSubTitles([
            {
              src: this.videos.subtitles[0].url,
              kind: "subtitles",
              label: this.videos.subtitles[0].name,
              srclang: lang,
              default: true
            },
            {
              src: this.videos.subtitles[1].url,
              kind: "subtitles",
              label: this.videos.subtitles[1].name,
              srclang: lang,
              default: false
            }
          ])
        }
      });
    },
    appSource() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        return "ios";
      } else {
        return "andriod";
      }
    },
    async gettimeUpdate() {
      const params = {
        mid: this.videos.id,
      }
      const result = await this.getTimeUpdateFn(params);
      if(result.code == 200){
        this.$nextTick(() => {
          this.init(this.videos,result.data.schedule);
        });
      }
    },
    async sendtimeUpdate(time) {
      const params = {
        mid: this.videos.id,
        time:time,
      }
      const result = await this.SubmitTimeUpdateFn(params);
    },
  },
  destroyed() {
    this.sendtimeUpdate(this.player.currentTime)
    gtag("event", "watch", {
      ct_is_free: this.moviesDetails.member_type == 1 ? true : false,
      ct_view_name: this.moviesDetails.name,
      ct_view_id: this.moviesDetails.id,
      ct_view_bufftertimes:this.times
    });
    this.player.hls.destroy()
    this.player.destroy()
  }
}
</script>

<style  scoped>
.main {
  margin: 0 auto;
  text-align: center;
  height: auto;
  width: auto;
}
::v-deep .xgplayer-texttrack .name{
  left: 0;
}
::v-deep .xgplayer-skin-default{
  min-height: 300px;
  width: 100vw !important;
  height: 100vh !important;
}
::v-deep .xgplayer-skin-default .xgplayer-pip .name span{
  padding: 0 20px;
  width: auto;
}
@media screen and (max-width:760px) {
  ::v-deep .xgplayer-skin-default{width: auto !important;height: auto !important}
  ::v-deep .xgplayer-skin-default .xgplayer-pip .name span{
    padding: 0px;
  }
  ::v-deep .xgplayer-skin-default .xgplayer-time{margin: 0}
  ::v-deep .xgplayer-skin-default .xgplayer-progress{
    width: 88%;
    left: 10%;
  }
}
</style>


