import api from "@/services/api"
import router from "@/router";

const state = () => ({
    trailerStatus: "simple",
    castlist: [],
    moviesDetails: [],
    moviesSimilar: [],
    moviesEpisode: [],
    playlist: null,
    loadLists: {
      obj:{
        loadStatus: false,
        loadStatusFull : false,
      }
    },
    Loadshow: true
});

const mutations = {
    SET_LOAD_TRANSIT(state, data) {
      state.Loadshow = data;
    },
    SET_LOAD_STATUS(state, obj) {
        state.loadLists = obj;
    },
    SET_TRAILER_STATUS(state, data) {
        state.trailerStatus = data;
    },
    SET_CAST_LIST(state, data) {
        state.castlist = data;
    },
    SET_MOVIES_DETAILS(state, data) {
        state.moviesDetails = data;
        state.playlist = data;
    },
    SET_MOVIES_SIMILAR(state, data) {
        state.moviesSimilar = data;
    },
    SET_MOVIES_EPISODE(state, data) {
        state.moviesEpisode = data;
    },
    SET_MOVIES_DETAIL(state, data){
        state.moviesDetails = data;
    }
};

const actions = {
    SetLoadShowFn({commit},data){
      commit("SET_LOAD_TRANSIT", data);
    },
    SetLoadStatusFn({commit},data){
        commit("SET_LOAD_STATUS", data);
    },
    SetTrailerStatusFn({commit}, data){
        commit("SET_TRAILER_STATUS", data);
    },
    async addLoadShowFn ({commit}, data) {
      const response = await new Promise(resolve => {
        setTimeout(() => {
          resolve( commit("SET_LOAD_TRANSIT", data));
        }, 500);
      });
    },
    SubmitBasicInfoFn({commit}, params){
        return api.submitBasicInfo(params)
            .then(res => {
                const { code } = res;
                if(code == 200){
                    console.log("Basic submit");
                }
                return code;
            })
    },
    SubmitMovieDetailFn({commit}, params){
        return api.submitMovieDetail(params)
            .then(res => {
                const { code } = res;
                if(code == 200){
                    return code;
                }
            })
    },
    SubmitTimeUpdateFn({commit}, params){
        return api.sendtimeUpdate(params)
            .then(res => {
                const { code, data, msg } = res;
                return code;
            })
    },
    // 全部演员信息
    getCastListFn({commit}, params){
        return api.getMovieInfoAll(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_CAST_LIST', data || [])
                }
                return res;
            })
    },
    // 播放详情
    getMovieDetailsFn({commit}, params){
        return api.getMovieDetails(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    if (data){
                        commit('SET_MOVIES_DETAILS', data)
                    }else{
                        router.push('/');
                    }
                }else{
                  router.push('/');
                }
                return res;
            })
    },
    getMoviesimilarFn({commit}, params){
        return api.getMoviesimilar(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    const array = data.list.filter(item => {
                        return item.id > 0;
                    });
                    console.log(array);
                    commit('SET_MOVIES_SIMILAR', array)
                }
                return code;
            })
    },
    getMovieepisodeFn({commit}, params){
        return api.getMovieepisode(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_MOVIES_EPISODE', data)
                }
                return code;
            })
    },
    getTimeUpdateFn({commit}, params){
        return api.getTimeUpdateFn(params)
            .then(res => res)
    },
};

export default {
    state,
    mutations,
    actions
}
