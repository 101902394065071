import Vue from 'vue'
export default {
    install(Vue){
        Vue.mixin({
            data() {
                return {
                    device: "web"
                };
            },
            mounted() {
                this.device = document.body.clientWidth > 768 ? "web" : "mob"
            }
        })
    }
}