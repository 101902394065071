<template>
    <section class="sign-in sign password">
        <div class="login-bg pass-bg">
            <i @click="SetPasswordStatusFn(false)" class="close"></i>
        </div>
        <div class="step-one" v-if="stepOne">
          <form>
            <div class="form-group">
              <input type="text" v-model="payload.email" class="form-control"
                     :placeholder="$t('login.2')"  @focus="error = ''"
              />
              <span v-show="show" @click="SendEmailHandler">{{ $t('login.9') }}<i></i></span>
              <span v-show="!show" class="count">{{count}}s<i></i></span>
            </div>
            <div class="form-group">
              <input type="password" v-model="payload.code" class="form-control"
                     :placeholder="$t('login.16')" @focus="error = ''"/>
            </div>
            <div class="bmtan clearfix bmtan-enroll">
              <span class="error pull-left">{{ error }}</span>
              <a class="submit-btn" @click="SendEmailnext">{{ $t('mine.7') }}</a>
            </div>
          </form>
        </div>
        <div class="step-two"  v-if="stepTwo">
          <h4>{{ $t('mine.13') }}</h4>
          <form>
            <p class="text">{{ $t('mine.9') }}</p>
            <div class="form-group">
              <input type="password" v-model="payload.pwd" class="form-control"
                     :placeholder="$t('login.15')" @focus="error = ''"/>
            </div>
            <p class="text">{{ $t('mine.14') }}</p>
            <div class="form-group">
              <input type="password" v-model="payload.repassword" class="form-control"
                     :placeholder="$t('mine.16')" @focus="error = ''"/>
            </div>
            <div class="bmtan clearfix">
              <span class="error pull-left">{{ error1 }}</span>
              <a class="submit-btn" @click="SendEmailSteptwo">{{ $t('mine.7') }}</a>
            </div>
          </form>
        </div>
        <div class="step-two"  v-if="stepThree">
          <div class="registrationSuccess">
            <h4>{{ $t('mine.15') }}</h4>
            <div @click="SetPasswordSuccessFn(false)" class="enjoy">{{ $t('login.21') }}</div>
          </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    name : "SignIn",
    data(){
        return {
            payload: {
                email: "", pwd: "", repassword: "", code: "",
            },
            error: "",
            error1: "",
            stepOne: true,
            stepTwo: false,
            stepThree: false,
            show: true,
            count: '',
            timer: null,
        }
    },
    methods: {
        ...mapActions({
            SetPasswordStatusFn:"SetPasswordStatusFn",
            EditEmailbackFn: "EditEmailbackFn",
            SendEmailFn: "SendEmailFn"
        }),
        async SendEmailHandler(){
          const res = this.$utils.checkEditEmailHandler(this.payload);
          if(res === "success"){
            const {email} = this.payload;
            const status = await this.SendEmailAxiosHandler({email});
          }else{
            this.error = res;
          }
        },
        SendEmailAxiosHandler(payload){
          this.error = "";
          const TIME_COUNT = 60;
          setTimeout( async () => {
            const result = await this.SendEmailFn(payload);
            if(result == 200){
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000)
              }
            }
          },1000);
        },
        SendEmailnext(){
          const res = this.$utils.checkEditPasswordnext(this.payload);
          if(res === "success"){
            this.stepOne = false
            this.stepTwo = true
          }else{
            this.error = res;
          }
        },
        async SendEmailSteptwo(){
           const {email,pwd,code} = this.payload;
            const res = this.$utils.checkEditPasswordSteptwo(this.payload);
            if(res === "success"){
                this.error1 = "";
                setTimeout( async () => {
                    const result = await this.EditEmailbackFn({email,pwd,code});
                    if(result == 200) {
                      this.stepTwo = false
                      this.stepThree = true
                    }
                },1000);
            }else{
                this.error1 = res;
            }
        },
        SetPasswordSuccessFn(){
            this.stepThree = false;
            this.SetPasswordStatusFn(false);
        }
    }
}
</script>