import Vue from 'vue'
import VueRouter from 'vue-router'
import Serial from '@/views/Serial/Serial'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject){
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch(err => err);
}
Vue.use(VueRouter)

const routes = [
    {
        path: "",
        name: "Home",
        component: () => import("../views/Home/Home.vue")
    },
    {
        path: "/exclusive",
        name: "exclusive",
        component: () => import("../views/aoflix/exclusive.vue"),
    },
    {
        path: "/search",
        name: "Search",
        component: () => import("../views/Search/Search.vue")
    },
    {
        path: "/short",
        name: "Short",
        component: () => import("../views/Short/Short.vue")
    },
    {
        path: "/trailer/:sid",
        name: "trailer",
        component: () => import("../views/Trailer/Trailer.vue")
    },
    {
        path: "/serial/:sid",
        name: "serial",
        component:Serial
    },
    {
        path: "/supplierAllBack",
        name: "supplierAllBack",
        component: () => import("../views/Serial/supplierAllBack.vue"),
    },
    {
        path: "/mine/HistoryRecord",
        name: "HistoryRecord",
        component: () => import("../views/Mine/HistoryRecord.vue"),
    },
    {
        path: "/mine/BillingDetails",
        name: "BillingDetails",
        component: () => import("../views/Mine/BillingDetails.vue"),
    },
    {
        path: "/SignApp",
        name: "SignApp",
        component: () => import("../views/SignApp/SignApp.vue"),
    },
    {
        path: "/Signview",
        name: "Signview",
        component: () => import("../views/SignApp/SignView.vue"),
    },
    {
        path: "/allmovies",
        name: "AllMovies",
        component: () => import("../views/AllMovies/AllMovies.vue"),
    },
    {
        path: "/Catalog",
        name: "Catalog",
        component: () => import("../views/Catalog/Catalog.vue"),
    },
    // 演员列表
    {
        path: "/castlist",
        name: "CastList",
        component: () => import("../views/CastList/CastList.vue"),
    },
    {
        path: "/castlist/castdetails",
        name: "CastDetails",
        component: () => import("../views/CastList/comp/CastDetails.vue"),
    },
    {
        path: "/director",
        name: "Director",
        component: () => import("../views/Director/Director.vue"),
    },
    {
        path: "/director/directordetails",
        name: "DirectorDetails",
        component: () => import("../views/Director/comp/DirectorDetails.vue"),
    },
    {
        path: "/terms",
        name: "Terms",
        component: () => import("../views/Terms/Terms.vue"),
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: () => import("../views/Terms/Privacy.vue"),
    },
    {
        path: "/Membership",
        name: "Membership",
        component: () => import("../views/Terms/Membership.vue"),
    },
    {
        path: "/DMCA",
        name: "DMCA",
        component: () => import("../views/Terms/Dmca.vue"),
    },
    {
        path: "/2257",
        name: "T2257",
        component: () => import("../views/Terms/T2257.vue"),
    },
    {
        path: "/Report",
        name: "Report",
        component: () => import("../views/Terms/Report.vue"),
    },
    {
        path: "/Refund",
        name: "Refund",
        component: () => import("../views/Terms/Refund.vue"),
    },
    {
        path: "/member",
        name: "Member",
        component: () => import("../views/Member/Member.vue"),
    },
    {
        path: "/memberpart",
        name: "MemberPart",
        component: () => import("../views/Member/MemberPart.vue"),
    },
    {
        path: "/modify",
        name: "Modify",
        component: () => import("../views/Modify/Modify.vue"),
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    let fromPath,toPath;
    if(from.path == '/'){
        fromPath = '/aoflix'
    }else{
        fromPath = from.path
    }
    if(to.path == '/'){
        toPath = '/aoflix'
    }else{
        toPath = to.path
    }
    if(fromPath !== '/'){
        gtag("event", "page_view", {
            page_title: toPath,   //url的路径
            page_location: window.location.protocol + '//' + window.location.hostname + toPath
        });
        _paq.push(['setReferrerUrl', window.location.protocol + '//' + window.location.hostname + from.path]);     //当前页面的上一个页面的URL
        _paq.push(['setCustomUrl', window.location.protocol + '//' + window.location.hostname + to.path]);        //window.location.protocol + window.location.hostname + to.path，当前页面URL
        _paq.push(['trackPageView']);
    }
    // 路由切换，滚动条在顶部
    document.getElementById("app").scrollTo(0,0);
    // 进入搜索页，保存当前路由
    if(to.path == '/search') {
        if(from.path !== '/search'){
            localStorage.setItem('preRoute', router.currentRoute.fullPath)
        }
    }
    if(toPath == '/aoflix') {
        if(fromPath !== '/path'){
            if (!window.localStorage.getItem("token")){
                    localStorage.setItem('path', router.history._startLocation)
            }
        }
    }
    next();
});

export default router
