<template>
    <section class="serial-page" ref="serialdetails">
        <wjs-header v-show="this.tops" @visibleChange="visibleChange"></wjs-header>
        <div class="video-position">
           <Servideo :videos="playlist" :url="videoUrl"  v-if="playlist && videoUrl"></Servideo>
          <div class="video-back" @click="routergo()" v-show="!tops">
            <img class="fic" src="@/assets/images/icons/title.png"/>
            {{ moviesDetails.name }}
          </div>
        </div>
        <div class="serial-details" v-if="moviesDetails">
          <div class="container-fixed">
            <div class="serial-detail">
              <div class="serial-name">{{ moviesDetails.name }}</div>
              <div class="serial-desc" :class="isShow? 'reverse':''">
                <p ref="serialdesc">{{ moviesDetails.desc }}</p>
                <span class="rightUp" @click="handlUp()" v-if="handlUpShow"><i class="el-icon-arrow-up" :class="isShow? 'reverse':''"></i></span>
              </div>
              <div class="serial-Cast lane serial-Cast-h5">
                <em>{{ $t('serial.4') }}:</em>
                <template v-for="(item, index) in moviesDetails.actor">
                  <span :key="index" v-if="moviesDetails.actor && moviesDetails.actor.length>1"  @click="handGoDetail(item.id,1)">{{ item.name+','}} </span>
                  <span :key="index" v-else @click="handGoDetail(item.id,1)">{{ item.name}} </span>
                </template>
              </div>
              <div  class="serial-tags" ref="serialtags">
                <div class="serial-content" ref="serialcontent" :class="extraWidth? 'extraWidth':''">
                  <div class="serial-tag" v-for="(item, index1) in moviesDetails.tag" :key="index1">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="serial-fixed">
                <div class="serial-Cast-pc">
                  <div class="serial-duration lane">
                    <em>{{ $t('serial.1') }}:</em>
                    <span>{{ moviesDetails.duration }} {{$t('serial.9') }}</span>
                  </div>
                  <div class="serial-release lane">
                    <em>{{ $t('serial.2') }}:</em>
                    <span>{{ moviesDetails.year_shooting }}</span>
                  </div>
                  <div class="serial-film lane">
                    <em>{{ $t('serial.3') }}:</em>
                    <span v-for="(item, index2) in moviesDetails.FilmCompany" :key="index2" @click="handGoDetail(item.id,2)">{{ item.name }}</span>
                  </div>
                  <div class="serial-Cast lane">
                    <em>{{ $t('serial.4') }}:</em>
                    <template v-for="(item, index5) in moviesDetails.actor">
                      <span :key="index5"  v-if="moviesDetails.actor && moviesDetails.actor.length>1"  @click="handGoDetail(item.id,1)">{{ item.name+','}} </span>
                      <span :key="index5"  v-else @click="handGoDetail(item.id,1)">{{ item.name}} </span>
                    </template>
                  </div>
                </div>
                <div class="serial-location" v-if="moviesDetails.location">
                  <em>{{ $t('serial.12') }}:</em>
                  <span>{{ moviesDetails.location }}</span>
                </div>
                <div class="serial-location serial-serialnum" v-if="moviesDetails.location">
                  <em>{{ $t('serial.13') }}:</em>
                  <span>{{ moviesDetails.serial_num }}</span>
                </div>
              </div>
            </div>
            <div class="serial-carousel">
              <div class="swiper"  v-if="moviesDetails.type != 1">
                <div class="title">
                  <p class="text"><i></i>{{ $t('serial.7') }}</p>
                  <div class="btn" v-if="moviesEpisode.list && moviesEpisode.list.length > 6">
                    <div @click="selectHandler(moviesEpisode.list,-1)" class="left" :class="!position?'active' : ''"></div>
                    <i ></i>
                    <div @click="selectHandler(moviesEpisode.list,1)" class="right" :class="position?'active' : ''"></div>
                  </div>
                </div>
                <div class="swiper-li">
                  <div ref="scrollbox" class="box">
                    <div class="swiper-item" v-for="(item, index2) in moviesEpisode.list"  @click="handPlay(item.id, item.member_type)" :key="index2">
                      <div class="ctntotal">
                        <div class="ctntop">
                          <span class="subscript-tag" v-if="item.is_subscript == 1"></span>
                          <img :src="item.cover? item.cover :require('@/assets/images/movies/Default_cover.png')" />
                          <div class="bottom_ctn">
                            <div class="bottoms">
                              <span class="play"></span>
                            </div>
                          </div>
                        </div>
                        <div class="ctnfoot">
                          <div>
                            <p>{{item.name}}</p>
<!--                            <span class="hd"></span>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper preview_img"  v-if="moviesDetails.preview_img && moviesDetails.preview_img.length != 0">
                <div class="title">
                  <p class="text"><i></i>{{ $t('serial.8') }}</p>
                  <div class="btn" v-if="moviesDetails.preview_img && moviesDetails.preview_img.length > 6">
                    <div @click="selectHandler1(moviesDetails.preview_img,-1)" class="left" :class="!position1?'active' : ''"></div>
                    <i></i>
                    <div @click="selectHandler1(moviesDetails.preview_img,1)" class="right" :class="position1?'active' : ''"></div>
                  </div>
                </div>
                <div class="swiper-li">
                  <div ref="scrollbox1" class="box">
                    <div class="swiper-item" v-for="(item, index3) in moviesDetails.preview_img" :key="index3" ref="elimage">
                      <div class="out-image">
                        <el-image
                            :src="item? item :require('@/assets/images/movies/Default_cover.png')"
                            :preview-src-list="moviesDetails.preview_img">
                        </el-image>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="serial-more">
          <div class="container-fixed" v-if="moviesSimilar">
              <p class="text"><i></i>{{ $t('serial.6') }}</p>
              <div>
                <MoviesList v-for="(item,index4) in moviesSimilar" :key="index4" :itemData="item"></MoviesList>
              </div>
          </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import WjsHeader from "@/components/Common/WjsHeader";
import Servideo from "@/components/Serial/Servideo"
import Serials from "@/components/Serial/Serials"
import MoviesList from '@/components/Common/MoviesList.vue'
export default {
    name: "SerialPage",
    data() {
        return {
            player: null,
            serials: {},
            curr : 0,
            curr1 : 0,
            position:true,
            position1:true,
            tops: false,
            playlist: null,
            visible: false,
            spacing: 0,
            videoUrl: "",
            extraWidth:false,
            isShow: false,
            handlUpShow: false,
        };
    },
    watch: {
      $route(to, from) {
        if (to.query.pid != from.query.pid) {
          // this.initParams();
        };
      },
      curr(a,b){
        let that = this.$refs.scrollbox
        let sizeCount =  this.sizeCount(that.offsetWidth,this.moviesEpisode.list.length)
        that.scrollTo({top:0,left:that.offsetWidth*this.curr})
        if(sizeCount == this.curr){
              this.position = false
        }else if (this.curr==0){
              this.position = true
        }
      },
      curr1(a,b){
        let that = this.$refs.scrollbox1
        let sizeCount =  this.sizeCount(that.offsetWidth,this.moviesDetails.preview_img.length)
        that.scrollTo({top:0,left:that.offsetWidth*this.curr1})
        if(sizeCount == this.curr1){
            this.position1 = false
        }else if (this.curr1==0){
            this.position1 = true
        }
      },
    },
   created() {
     this.SetLoadStatusFn( { loadStatus: true});
   },
   mounted() {
      this.$nextTick(async ()=>{
        const { pid } = this.$route.query;
        const result1 = await this.SendmoviesPlay(pid);
        this.returnTop()
        window.addEventListener('scroll',this.topscrollTo,true)
      })
    },
    computed: {
      ...mapState({
        moviesDetails: (state) => state.serial.moviesDetails,
        moviesSimilar: (state) => state.serial.moviesSimilar,
        moviesEpisode: (state) => state.serial.moviesEpisode,
        userMember: (state) => state.home.userMember
      }),
    },
    methods: {
        ...mapActions({
            SetLoadStatusFn: "SetLoadStatusFn",
            getMovieDetailsFn: "getMovieDetailsFn",
            getMoviesimilarFn: "getMoviesimilarFn",
            getMovieepisodeFn: "getMovieepisodeFn",
            getAddPlayListFn: "getAddPlayListFn",
            getMovieInfoPlaysFn:"getMovieInfoPlaysFn",
            SetLoadShowFn: "SetLoadShowFn",
            addLoadShowFn:"addLoadShowFn",
            getDataNikkatsuFn: "getDataNikkatsuFn"
        }),
        handlUp () {
          if( this.device =='mob') {
            this.isShow = !this.isShow;
          }
        },
        countwidth(){
          setTimeout( async () => {
            let that = this.$refs.serialcontent.querySelectorAll('div'),
                thats = this.$refs.serialtags.offsetWidth, sum=0,desc = this.$refs.serialdesc;
            for(let i = 0; i < that.length; i++){
              sum= sum + that[i].offsetWidth+10;
            };
            if (+sum>thats){
              this.extraWidth = true
            }
            if (desc.offsetHeight > 60) {
              this.handlUpShow = true
              this.isShow = true
            }
          },1000);
        },
        routergo(){
          const { type } = this.$route.query;
          if (type==1){
            this.$router.go(-2);
          }else{
            this.$router.go(-1);
          }
        },
        visibleChange(e){
          this.$nextTick(() => {
              this.visible = e
          })
        },
       sizeCount:function(size,curr){
         size = size+80;
         let space
         switch(true) {
           case (size > 1600):
             space = 6
             break;
           case (1600 >= size)&&(size > 1366):
             space = 5
             break;
           case (1366 >= size)&&(size > 1024):
             space = 4
             break;
           case (1024 >= size)&&(size > 768):
             space = 3
             break;
           case (size <= 768):
             space = 2
             break;
           default:
         }
         let respace = curr/space
         if (respace==1){
           respace = 0
         }
         return parseInt(respace)
        },
        returnTop(){
          this.$nextTick(() => {
            let that =document.getElementById("app");
            that.scrollTop = 0;
          })
        },
        topscrollTo(e){
          this.$nextTick(() => {
            var that = document.getElementById("app").scrollTop;
            if (that > 50 || this.visible){
              this.tops = true
            }else{
              this.tops = false
            }
          })
        },
        handGoDetail(id,type){
          let path;
          if (type==1){
            path = '/castlist/castdetails';
          }else{
            path = '/filmcompanies/filmdetails';
          }
          if(id){
            this.$router.push({
              path: path,
              query: {id: id,},
            });
          }else{
            this.$message({
              message: this.$t('serial.10'),
              type: "warning",
            });
          }
        },
        handPlay(pid, member_type) {
          // 发送统计上报
          const sendDataNikkatsuFn = async() => {
                const result = await this.getDataNikkatsuFn( {up_type: 6} );
            }
          sendDataNikkatsuFn();

          if((Object.keys(this.userMember).length > 0 && this.userMember.isVip == 1) || member_type == 1) {
            this.SetLoadShowFn(false);
            this.addLoadShowFn(true).then(res => {
              const { sid } = this.$route.params;
              if (sid){
                this.$router.replace(`/supplierAllBack?pid=${pid}`);
              }else{
                this.$router.push(`/serial/${pid}?pid=${pid}`);
              }
            })
          } else {
            this.$router.push("/member");
          }
        },
        async addPlayList(id) {
          const params = {
            mid: String(id),
          }
          const result = await this.getAddPlayListFn(params);
        },
        //   获取数据
        async SendMoviesimilar(pid) {
          const params = {
            id:pid,
          }
          const result = await this.getMoviesimilarFn(params);
          if (result == 200){
            setTimeout( async () => {
               if(this.moviesDetails.type !=1 ) this.SendmoviesEpisode(pid)
            },200);
          }
        },
        async SendmoviesPlay(pid) {
          const params = {
            id:pid
          }
          const result = await this.getMovieInfoPlaysFn(params);
          if(result.code == 200){
              this.videoUrl = result.data.url;
              const result2 = await this.SendmoviesDetails(pid)
              this.countwidth()
          }else if(result.code == 1015){
            this.SetLoadStatusFn( { loadStatus: false});
            this.$router.replace("/member");
          }else{
            this.SetLoadStatusFn( { loadStatus: false});
            this.$router.push("/");
          }
        },
        async SendmoviesDetails(pid) {
          const params = {
            id:pid
          }
          const result = await this.getMovieDetailsFn(params);
          if (result.code== 200){
            this.playlist = result.data;
            this.SendMoviesimilar(pid)
          }
        },
        async SendmoviesEpisode(pid) {
          const params = {
            id:pid
          }
          const result = await this.getMovieepisodeFn(params);
        },
       selectHandler(types,val){
         let that = this.$refs.scrollbox
         let sizeCount =  this.sizeCount(that.offsetWidth,this.moviesEpisode.list.length)
          this.curr+=val
          if (this.curr<0){
            this.curr=0
          }
          else if(this.curr>sizeCount){
            this.curr= sizeCount
          }
        },
        selectHandler1(types,val){
          let that = this.$refs.scrollbox1
          let sizeCount =  this.sizeCount(that.offsetWidth,this.moviesDetails.preview_img.length)
          this.curr1+=val
          if (this.curr1<0){
            this.curr1=0
          }
          else if(this.curr1>sizeCount){
            this.curr1= sizeCount
          }
        },
    },
    components: {
        WjsHeader,
        Servideo,
        Serials,
        MoviesList
    },
}
</script>
<style>
.el-image{
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
}
.el-image img{
  display: block;
  height: auto;
  width: auto;
}
.el-image-viewer__next, .el-image-viewer__prev{
   background: 0;
}
.el-image-viewer__canvas .el-image-viewer__img{
   max-width: 80% !important;
   max-height: 80% !important;
}
.el-image-viewer__next i:before, .el-image-viewer__prev i:before{
    display: block;
    content:"";
    width: 24px;
    height: 24px;
    background: url('@/assets/images/movies/a_left-arrow.png') no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}
.el-image-viewer__next i:before{
   background: url('@/assets/images/movies/a_right-arrow.png') no-repeat;
   background-size: 100% 100%;
}
.el-tabs--border-card>.el-tabs__header,
.el-tabs--border-card{
  background: #192129;
  border: 0;
  box-shadow: none;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item{
  background: #323F4B;
  border-radius: 2px;
  color: #CBD2D9;
  font-size: 14px;
  margin-right: 20px;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  background: #FF0000;
  color: #CBD2D9;
  border: 0;
}
.el-tabs__nav-scroll{
  border-bottom: 1px solid #323F4B;
  padding-bottom: 12px;
}
.el-tabs--border-card>.el-tabs__content{
  padding: 12px 0;
}
</style>
<style scoped lang="scss">
.main {
  width: auto;
  height: auto;
  min-height: auto;
}
.swiper{
  .title{
    display: flex;
    margin: 25px 0;
    justify-content: space-between;
    margin-right: 10px;
    .text{
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      i{
        width: 4px;
        height: 24px;
        background: #FF0000;
        display: inline-block;
        vertical-align: top;
        margin-right: 16px;
      }
    }
    .btn{
      display: flex;
      align-items: center;
      i{
        display: block;
        width: 2px;
        height: 16px;
        background: #7B8794;
        margin: 0 16px;
      }
      .left{
        width: 24px;
        height: 24px;
        background: url('@/assets/images/movies/left-arrow.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .right{
        width: 24px;
        height: 24px;
        background: url('@/assets/images/movies/right-arrow.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .left.active{
        background: url('@/assets/images/movies/a_left-arrow.png') no-repeat;
        background-size: 100% 100%;
      }
      .right.active{
        background: url('@/assets/images/movies/a_right-arrow.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .swiper-li{
    width:100%;
    .box{
      display: flex;
      overflow: hidden;
    }
    .swiper-item{
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      cursor: pointer;
      width: 16%;
      margin: 0 0.8% 0 0;
      .ctntotal{
        height: 208px;
        .ctntop{
          width: 100%;
          height: 80%;
          position: relative;
          img{
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 50% 10%;
          }
          .bottom_ctn{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            display: none;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.3);
            .bottoms{
              .play{
                display: inline-block;
                width: 40px;
                height: 40px;
                background: url('@/assets/images/movies/bo.png') no-repeat top left;
                background-size: 100% 100%;
                margin: 0 15px 0 0;
                cursor: pointer;
              }
            }
          }
        }
        .ctnfoot{
          width: 100%;
          height: 20%;
          background: #131313;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          overflow: hidden;
          div{
            align-items: center;
            display: flex;
            padding: 0 10px;
            p{
              font-weight: 700;
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .hd{
              display: inline-block;
              width: 29px;
              height: 20px;
              min-width: 20px;
              background: url('@/assets/images/movies/hd.png') no-repeat top left;
              background-size: 100% 100%;
              margin-left: 8px;
            }
          }
        }
      }
      .out-image{
        width: 100%;
        height: 208px;
        background: #131313;
      }
    }
    .swiper-item:hover .bottom_ctn{
       display: flex;
    }
  }
}
.video-position{
  position: relative;
  .video-back{
    background: linear-gradient(180deg, #101419 0%, rgba(16, 20, 25, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    z-index: 100;
    img{
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: inline-block;
      margin: 0 20px 0 2%;
    }
  }
}
</style>
