<template>
     <div id="app" scroll-container>
        <load-cover v-if="this.loadLists.loadStatus" :class="this.loadLists.loadStatusFull ? 'full' : '' "></load-cover>
        <announce-ment v-if="showPopupintegral" @confirmEnter="confirmEnter"></announce-ment>
        <transition-group name="fade" mode="out-in" v-show="isshow">
            <div v-show="Loadshow" :key="1">
                <section class="main" ref="main">
                    <router-view></router-view>
                </section>
                <wjs-footer id="footer" v-if="$route.path != '/Signview' && $route.path !='/memberpart'"></wjs-footer>
            </div>
        </transition-group>
        <section v-if="coverStatus" class="background-cover" @touchmove.prevent></section>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import LoadCover from "@/components/Load/LoadCover";
import WjsFooter from "@/components/Common/WjsFooter";
import announceMent from "@/components/Load/Announcement";
export default {
    name: "application",
    data(){
        return {
            isshow: false,
            showPopupintegral:false,
        }
    },
    computed: {
        ...mapState({
            Loadshow: (state) => state.serial.Loadshow,
            loadLists: (state) => state.serial.loadLists,
            coverStatus: (state) => state.account.coverStatus,
            token: (state) => state.account.token,
            userMember: (state) => state.home.userMember
        }),
    },
    created(){
        const tokens = this.getQueryString("tokens",1);
        if (tokens){
          window.localStorage.setItem('token', tokens);
        }else{
            const from = this.getQueryString("from",1);
            if (from){
              this.SetSigupStatusFn(true)
            }
        }
        setTimeout(()=>{
            this.isshow = true;
        },800)
        this.ResetAccountState();
        const action = this.getQueryString("action",1);
        const whereFrom = this.getQueryString('utm_source',1);
        const platform = this.getQueryString("platform",1);
        if(platform == 'app'){
            window.localStorage.setItem('platform', 'app');
            this.SetSiginStatusFn(true);
        }else{
            window.localStorage.setItem('platform', 'web');
        }

        if (whereFrom !=null){
            window.localStorage.setItem('whereFrom', whereFrom);
        }
        if(action == "success"){
            const token = this.getQueryString("token",1);
            const login_types = this.getQueryString("login_type",1);
            const plat = window.localStorage.getItem('platform');
            const source = this.getQueryString("source",1);
            try{
                if(AndroidClient){
                    AndroidClient.onLoginResult(token,login_types,source);
                    AndroidClient.closeWebview()
                }
            }catch(err){
                console.log(err);
            }

            if (login_types == 2){
                const params = { link_spread: window.localStorage.getItem("whereFrom") || ''};
                const Spread = this.PostlinkSpreadFn(params);
            }
            window.localStorage.setItem('token', token);
            window.localStorage.setItem('urlSearch', window.location.search);
            setTimeout( async () => {
                    window.location.href = "/";
            },200);
        } else if(action == "email"){
          var urlsource = this.getQueryString('source',1);
          if (urlsource == 2){
            urlsource = 'google'
          }else if (urlsource == 1){
            urlsource = 'facebook'
          }else if(urlsource == 3){
            urlsource = 'twitter'
          }
          setTimeout(() => {
            this.SetBdemlStatusFn(true);
            window.localStorage.setItem('urlsource',urlsource);
          },1000);
        } else if(action == "error"){
            this.$message({
                message: this.$t('login.26'),
                type: "warning",
            });
        }else{
            console.log("url error");
        }
        if (this.loadLists.loadStatus){
            this.getSiteList({})
        }

        if(!localStorage.getItem("showPopupintegral")){
            if (window.location.pathname!="/memberpart"){
              this.showPopupintegral = true;
            }
            this.SetFirstEnterFn(false)
            setTimeout( async () => {
                gtag("event", "ct_popup_newvisitor");
            },1000);
        }else{
            this.SetFirstEnterFn(true)
        }
        if(document.body.clientWidth < 768) this.SetTrailerStatusFn("complex");
    },
    async mounted(){
        const CTYPE = this.device == "mob" ? 1 : 2;
        const params = { up_type: 1, c_type: CTYPE};
        const result = await this.getDataNikkatsuFn(params);
        this.processGtag()
    },
    methods: {
        ...mapActions({
            ResetAccountState: "ResetAccountState",
            SetTrailerStatusFn: "SetTrailerStatusFn",
            getSiteList: "getSiteList",
            SetBdemlStatusFn: "SetBdemlStatusFn",
            getDataNikkatsuFn: "getDataNikkatsuFn",
            SetFirstEnterFn:"SetFirstEnterFn",
            PostlinkSpreadFn: "PostlinkSpreadFn",
            SetSiginStatusFn: "SetSiginStatusFn",
            SetSigupStatusFn: "SetSigupStatusFn",
        }),
        confirmEnter(e){
            if (e){
                localStorage.setItem("showPopupintegral", "true");
                this.showPopupintegral = false
                this.SetFirstEnterFn(true)
            }
        },
        getQueryString(name,type) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            if (type==1){
                var r = window.location.search.substring(1).match(reg);
            }else{
                var r = window.localStorage.getItem("urlSearch").substring(1).match(reg);
            }
            if(r != null) return r[2];
            return null;
        },
        processGtag(){
            var environment = process.env.VUE_APP_MODE;
            _paq.push(['setUserId',this.userMember.uid || null]);         //用户id，1、2、3…    如果没有就空值/不传
            _paq.push(['setSiteId', environment == 'uat'?'4':'5']);            //4是UAT环境，5是正式环境
            _paq.push(['setCustomDimension', 2, window.localStorage.getItem("locale") || "1"]);                 //站点语言，1、2、3…
            _paq.push(['trackEvent', 'pageload', 'pageload', {'nonInteraction': 1}]);
            if (environment == 'uat'){
                //测试
                gtag('config', 'G-P85F8KT2YV', {
                    page_title: window.location.pathname,
                    user_id: this.userMember.uid || null,
                    'user_properties': {
                        ct_language_id: window.localStorage.getItem("locale") || "1"
                    }
                });
            }else{
                //线上
                gtag('config', 'G-8ES73C1BLT', {
                    page_title: window.location.pathname,
                    user_id: this.userMember.uid || null,
                    'user_properties': {
                        ct_language_id: window.localStorage.getItem("locale") || "1"
                    }
                });
            }
            setTimeout( async () => {
                if (window.localStorage.getItem("urlSearch")){
                    const login_type = this.getQueryString("login_type",2);
                    const source = this.getQueryString('source',2);
                    let method1,method2,types;
                    if (source == 2){
                        method1 = 'google'
                    }else if (source == 1){
                        method1 = 'facebook'
                    }else if(source == 3){
                        method1 = 'twitter'
                    }
                    if (login_type == 1){
                        method2 = '登录'
                        types = 'login'
                        gtag("event", "page_view", {
                          page_title: "login_success",
                          page_location: "https://" + window.location.host + "/login_success"
                        });
                    }else if (login_type == 2){
                        method2 = '注册'
                        types = 'sign_up'
                    }else if(login_type == 3){
                        method2 = '自动绑定登录'
                        types = 'login'
                    }
                    if(this.userMember){
                        gtag("event", types, {
                            user_id: this.userMember.uid,
                            user_properties: {
                                ct_user_id: this.userMember.uid,
                                ct_isvip: this.userMember.isVip == 1 ? true : false,
                                ct_method: method1+method2
                            }
                        });
                        _paq.push(['setUserId', this.userMember.uid]);         //用户id，1、2、3…
                        _paq.push(['setCustomDimension', 1, this.userMember.isVip == 1 ? true : false]);    // //当前状态是否是会员：true/false
                        _paq.push(['trackEvent',
                            method2,
                            method2+'成功',
                            method1]);     //注册/登录方式：直接、google、facebook、twitter
                        window.localStorage.setItem('urlSearch','');
                    }
                }
            },5000);
        }
    },
    components: {
        LoadCover,
        WjsFooter,
        announceMent
    },
};


</script>
<style>
.fade-enter-active, .fade-leave-active {
    transition:All 1s ease-in-out;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: scale(1.05);
}
</style>
