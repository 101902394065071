<template>
    <section class="sign-in sign">
        <div class="login-bg" :class="locale=='2'?'zh':''">
          <i @click="SetSiginStatusFn(false)" class="close"></i>
        </div>
        <form>
          <div class="form-group">
                <input type="text" v-model="payload.email" class="form-control"
                    :placeholder="$t('login.2')" @focus="error = ''"
                />
          </div>
          <div class="form-group">
                <input type="password" v-model="payload.pwd" class="form-control"
                       :placeholder="$t('login.15')" @focus="error = ''"/>
          </div>
          <div class="remps">
            <span @click="signUpfromPasswordHandler" >{{ $t('mine.17') }}</span>
          </div>
          <div class="bmtan clearfix">
            <span class="error pull-left">{{ error }}</span>
            <a class="submit-btn" @click="signInHandler">{{ $t('login.4') }}</a>
          </div>
          <div class="sf-title">
            <span class="line"></span>
            <span class="text">{{ $t('login.27') }}</span>
          </div>
          <div class="sf-cont">
            <img src="@/assets/images/common/google.png" @click="othSignInHandler(2,'google')" />
            <img src="@/assets/images/common/facebook.png" @click="othSignInHandler(1,'facebook')" />
            <img src="@/assets/images/common/twitter.png" @click="othSignInHandler(3,'twitter')" />
          </div>
          <div class="go-signup clearfix">
            <p>
              <span class="line"></span>
              {{ $t('login.5') }}<span @click="signUpfromSignInHandler">{{ $t('login.6') }}</span>
            </p>
          </div>
        </form>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Message } from 'element-ui';
import i18n from '@/assets/i18n';

export default {
    name : "SignIn",
    data(){
        return {
            payload: {
                email: "", pwd: ""
            },
            checked: false,
            error: "",
            locale:window.localStorage.getItem("locale")
        }
    },
    computed: {
      ...mapState({
        mine: (state) => state.account.mine,
        userMember: (state) => state.home.userMember
      })
    },
    mounted(){
      //绑定事件
      window.addEventListener('keydown',this.keyDown);
      window.othSignInHandler = this.othSignInHandler;
    },
   methods: {
          ...mapActions({
              SetLoadStatusFn: "SetLoadStatusFn",
              SetSiginStatusFn: "SetSiginStatusFn",
              SetSigupStatusFn: "SetSigupStatusFn",
              SetPasswordStatusFn: "SetPasswordStatusFn",
              AccountSignInFn: "AccountSignInFn",
              FetchUserInfoFn: "FetchUserInfoFn",
              FetchUserMemberFn: "FetchUserMemberFn",
              FetchMentLoginFn: "FetchMentLoginFn",
              SetCoverStatusFn: "SetCoverStatusFn",
              getMemberStatusFn: "getMemberStatusFn",
          }),
           keyDown(e){
             //如果是回车则执行登录方法
             if(e.keyCode == 13){
               //需要执行的方法
               this.signInHandler();
             }
           },
          async signInHandler(){
              const res = this.$utils.checkSignInHandler(this.payload);
              if(res === "success"){
                 this.SetLoadStatusFn({loadStatus: true});
                  this.error = "";
                  setTimeout( async () => {
                      const result = await this.AccountSignInFn(this.payload);
                      if(result == 200) this.SetSiginStatusFn(false);
                      this.SetLoadStatusFn({loadStatus: false});
                      if(result == 200){
                        const mnResult = await this.FetchUserMemberFn();
                        const mnResult2 = await this.FetchUserInfoFn();
                        if (this.$route.path == '/member'){
                          const mnResult3 = await this.getMemberStatusFn()
                        }
                        if(this.userMember){
                          gtag("event", "page_view", {
                              page_title: "login_success",
                              page_location: "https://" + window.location.host + "/login_success"
                          });
                          gtag("event", "login", {
                            user_id: this.userMember.uid,
                            user_properties: {
                              ct_user_id: this.userMember.uid,
                              ct_isvip: this.userMember.isVip == 1 ? true : false,
                              ct_method: '直接'               //注册方式：直接、google、facebook、twitter
                            }
                          });
                          _paq.push(['setUserId', this.userMember.uid]);     //用户id，1、2、3…
                          _paq.push(['setCustomDimension', 1, this.userMember.isVip == 1 ? true : false]);  // //当前状态是否是会员：true/false
                          _paq.push(['trackEvent',
                            '注册登录',
                            '登录成功',
                            '直接']);   //注册/登录方式：直接、google、facebook、twitter
                        }
                      }

                  },1000);
              }else{
                  this.error = res;
              }
          },
          signUpfromSignInHandler(){
              this.SetSiginStatusFn(false);
              this.SetSigupStatusFn(true)
          },
          signUpfromPasswordHandler(){
            this.SetSiginStatusFn(false);
            this.SetPasswordStatusFn(true);
          },
          async othSignInHandler(param,type){
            const params = { type: param};
            const result = await this.FetchMentLoginFn(params);
            gtag("event", "ct_click", {
                ct_click_type:  "第三方关联跳转",
                ct_click_name:  type
            });
            if(result.code == 200){
               const { url } = result.data;
               setTimeout( async () => {
                window.location.href = url;
               },1000);
            }
          }
      },
      destroyed(){
        window.removeEventListener('keydown',this.keyDown,false);
      },
}
</script>

