import Vue from 'vue'
import Vuex from 'vuex'
import serial from './serial'
import account from './account'
import movies from './movies'
import home from './home'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        serial,
        account,
        movies,
        home
    }
})
