<template>
    <div class="videolist"  @click="handPlay(itemData.id, itemData.member_type)">
        <div class="nctntop">
            <span class="subscript-tag" v-if="itemData.is_subscript == 1"></span>
            <img class="nimg_bj" v-lazy="itemData.cover" alt="" />

            <!-- <img class="nimg_bj" :src="itemData.cover? itemData.cover :require('@/assets/images/movies/Default_cover.png')" alt=""> -->
            <div class="bottom_ctn">
                <div class="bottoms">
                    <span class="play"></span>
                    <!-- <span class="add" @click="addPlayList(itemData.id)"></span> -->
                </div>
            </div>
        </div>
        <ul class="videofooter">
            <li>
                <p class="text">{{itemData.name}}</p>
<!--                <span class="hd"></span>-->
            </li>
            <li class="textLi">
                <p v-html="itemData.desc"></p>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import './css/MoviesList.scss'


export default {
    name: 'MoviesList',
    props: {
        itemData: {type: Object, default : () => {}}
    },
    computed:{
        ...mapState({
            token: (state) => state.account.token,
            userMember: (state) => state.home.userMember
        })
    },
    methods: {
        ...mapActions({
            getAddPlayListFn: "getAddPlayListFn",
            SetSiginStatusFn: "SetSiginStatusFn",
            SetSigupStatusFn: "SetSigupStatusFn",
            SetLoadShowFn: "SetLoadShowFn",
            addLoadShowFn:"addLoadShowFn",
            getDataNikkatsuFn: "getDataNikkatsuFn"
        }),

        //   添加到播放列表
      async addPlayList(movie_id) {
            if(!this.token){
                this.SetSiginStatusFn(true);
                this.SetSigupStatusFn(false);
                return false;
            }
            const params = {
                mid: String(movie_id),
            }
            const result = await this.getAddPlayListFn(params);
        },
        // 播放
        handPlay(pid, member_type) {
            // 发送统计上报
            const sendDataNikkatsuFn = async() => {
                const result = await this.getDataNikkatsuFn( {up_type: 6} );
            }
            sendDataNikkatsuFn();

            if(!this.token){
                window.localStorage.setItem('path', `/serial/${pid}?pid=${pid}`);
                this.SetSiginStatusFn(true);
                this.SetSigupStatusFn(false);
                return false;
            }
            if((Object.keys(this.userMember).length > 0 && this.userMember.isVip == 1) || member_type == 1) {
                const { sid } = this.$route.params;
                this.SetLoadShowFn(false);
                this.addLoadShowFn(true).then(res => {
                if (sid){
                    this.$router.replace(`/supplierAllBack?pid=${pid}`);
                }else{
                    this.$router.push(`/serial/${pid}?pid=${pid}`);
                }
                })
            } else {
                this.$router.push("/member");
            }
        },
    },
};
</script>
