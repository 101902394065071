import i18n from '../assets/i18n/';

class UtilClass {

    constructor() {

        this.data = [
            {
                sid: 1,
                nav: "首页",
                name: "《绝命毒师》成人番外篇",
                release: "2022",
                producter: "污剧社",
                total: 5,
                star: "star5",
                desc: "老白在得知自己身患绝症，为保障家人在自己死后衣食无忧，决意运用自己的化学技术提炼出高纯度的毒品和春药，为缓解制毒带来的紧张和刺激，老白联系女郎缓解忧虑，由此逐步沉迷性爱，开始了游走于制毒与女郎间的双重生活。",
                trailer: {
                    web: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8",
                    mob: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8"
                },
                videos: [
                    {
                        cover: "1/1",
                        media: "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4",
                    },
                    {
                        cover: "1/2",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/ZTVjNzFiY2/170125/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/3",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/NTliYjEwMD/173507/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/4",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/MjcxMmE5Mj/100137/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/5",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/ZmQ0MWE2Mm/095820/1080/hls/decrypt/index.m3u8",
                    },
                ]
            },
            {
                sid: 2,
                nav: "全部影片",
                name: "《绝命毒师》成人番外篇",
                release: "2022",
                producter: "污剧社",
                total: 5,
                star: "star5",
                desc: "老白在得知自己身患绝症，为保障家人在自己死后衣食无忧，决意运用自己的化学技术提炼出高纯度的毒品和春药，为缓解制毒带来的紧张和刺激，老白联系女郎缓解忧虑，由此逐步沉迷性爱，开始了游走于制毒与女郎间的双重生活。",
                trailer: {
                    web: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8",
                    mob: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8"
                },
                videos: [
                    {
                        cover: "1/1",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220922/OGQ5Nzc0Zj/233218/720/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/2",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/ZTVjNzFiY2/170125/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/3",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/NTliYjEwMD/173507/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/4",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/MjcxMmE5Mj/100137/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/5",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/ZmQ0MWE2Mm/095820/1080/hls/decrypt/index.m3u8",
                    },
                ]
            },
            {
                sid: 3,
                nav: "演员列表",
                name: "《绝命毒师》成人番外篇",
                release: "2022",
                producter: "污剧社",
                total: 5,
                star: "star5",
                desc: "老白在得知自己身患绝症，为保障家人在自己死后衣食无忧，决意运用自己的化学技术提炼出高纯度的毒品和春药，为缓解制毒带来的紧张和刺激，老白联系女郎缓解忧虑，由此逐步沉迷性爱，开始了游走于制毒与女郎间的双重生活。",
                trailer: {
                    web: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8",
                    mob: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8"
                },
                videos: [
                    {
                        cover: "1/1",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220922/OGQ5Nzc0Zj/233218/720/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/2",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/ZTVjNzFiY2/170125/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/3",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/NTliYjEwMD/173507/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/4",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/MjcxMmE5Mj/100137/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/5",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/ZmQ0MWE2Mm/095820/1080/hls/decrypt/index.m3u8",
                    },
                ]
            },
            {
                sid: 4,
                nav: "片商",
                name: "《绝命毒师》成人番外篇",
                release: "2022",
                producter: "污剧社",
                total: 5,
                star: "star5",
                desc: "老白在得知自己身患绝症，为保障家人在自己死后衣食无忧，决意运用自己的化学技术提炼出高纯度的毒品和春药，为缓解制毒带来的紧张和刺激，老白联系女郎缓解忧虑，由此逐步沉迷性爱，开始了游走于制毒与女郎间的双重生活。",
                trailer: {
                    web: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8",
                    mob: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8"
                },
                videos: [
                    {
                        cover: "1/1",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220922/OGQ5Nzc0Zj/233218/720/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/2",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/ZTVjNzFiY2/170125/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/3",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/NTliYjEwMD/173507/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/4",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/MjcxMmE5Mj/100137/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/5",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/ZmQ0MWE2Mm/095820/1080/hls/decrypt/index.m3u8",
                    },
                ]
            },
            {
                sid: 5,
                nav: "导演",
                name: "《绝命毒师》成人番外篇",
                release: "2022",
                producter: "污剧社",
                total: 5,
                star: "star5",
                desc: "老白在得知自己身患绝症，为保障家人在自己死后衣食无忧，决意运用自己的化学技术提炼出高纯度的毒品和春药，为缓解制毒带来的紧张和刺激，老白联系女郎缓解忧虑，由此逐步沉迷性爱，开始了游走于制毒与女郎间的双重生活。",
                trailer: {
                    web: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8",
                    mob: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8"
                },
                videos: [
                    {
                        cover: "1/1",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220922/OGQ5Nzc0Zj/233218/720/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/2",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/ZTVjNzFiY2/170125/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/3",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/NTliYjEwMD/173507/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/4",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/MjcxMmE5Mj/100137/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/5",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/ZmQ0MWE2Mm/095820/1080/hls/decrypt/index.m3u8",
                    },
                ]
            },
        ];

        this.homeData = {
            "1": [
                {
                    id: 17,
                    title: "Molly RedWolf, Cherry Acid and Zirael Rem",
                    tags: ["18+", "2022", "45 mins", "98 Match"],
                    desc: "Molly, Cherry and Zirael are three gorgeous anime babes eager to fuck each other! The hottest threesome ensues as they keep themselves busy with each other’s pussies!",
                    cover: "poster_1",
                    media: "https://qzvd-newblibli3.10cb1c.com/20221028/YzFmYWI2NT/144621/720/hls/decrypt/index.m3u8"
                },
                {
                    id: 18,
                    title: "MILF Gets Grocery Store Dick",
                    tags: ["18+", "2022", "30 mins", "98 Match"],
                    desc: "Jimmy Michaels is stocking shelves at the grocery store when one of his Lil Humper buddies points out stacked MILF Ashlyn Peaks and her big melons. ",
                    cover: "poster_2",
                    media: "https://qzvd-newblibli3.10cb1c.com/20221029/MWQ0NzFlZW/104619/1080/hls/decrypt/index.m3u8"
                },
                // {
                //     id: 19,
                //     title: "Amazing Newcomer…?",
                //     tags: ["18+", "2022", "124 mins", "98 Match"],
                //     desc: "A Mysterious DAHLIA Exclusive Unequaled Beauty With A",
                //     cover: "poster_3",
                //     media: "https://qzvd-newblibli3.10cb1c.com/20221029/YjViNmVhNW/104442/1080/hls/decrypt/index.m3u8"
                // },
                // {
                //     id: 20,
                //     title: "Natsu Igarashi",
                //     tags: ["18+", "2022", "120 mins", "98 Match"],
                //     desc: "A Female Employee Who Has Been Harassed By Sticky Boobs 5 Times",
                //     cover: "poster_4",
                //     media: "https://qzvd-newblibli3.10cb1c.com/20221029/M2U3NzJlND/104417/1080/hls/decrypt/index.m3u8"
                // },
                // {
                //     id: 21,
                //     title: "Alex Coal, Jewelz Blu, Harlowe Blue and Val Steele",
                //     tags: ["18+", "2022", "76 mins", "98 Match"],
                //     desc: "Enjoy this compilation of the hottest goth babes getting it on! Featuring goth babes such as Alex Coal, Harlowe Blue, Jewelz Blu, Val Steele, and more!",
                //     cover: "poster_5",
                //     media: "https://qzvd-newblibli3.10cb1c.com/20221028/YmJiNjU0MD/144720/720/hls/decrypt/index.m3u8"
                // }
            ],
            "2": [
                {
                    id: 17,
                    title: "Molly RedWolf, Cherry Acid and Zirael Rem",
                    tags: ["18+", "2022", "45 mins", "98 Match"],
                    desc: "Molly, Cherry and Zirael are three gorgeous anime babes eager to fuck each other! The hottest threesome ensues as they keep themselves busy with each other’s pussies!",
                    cover: "poster_1",
                    media: "https://qzvd-newblibli3.10cb1c.com/20221028/YzFmYWI2NT/144621/720/hls/decrypt/index.m3u8"
                },
                {
                    id: 18,
                    title: "MILF Gets Grocery Store Dick",
                    tags: ["18+", "2022", "30 mins", "98 Match"],
                    desc: "Jimmy Michaels is stocking shelves at the grocery store when one of his Lil Humper buddies points out stacked MILF Ashlyn Peaks and her big melons. ",
                    cover: "poster_2",
                    media: "https://qzvd-newblibli3.10cb1c.com/20221029/MWQ0NzFlZW/104619/1080/hls/decrypt/index.m3u8"
                },
                // {
                //     id: 19,
                //     title: "A Mysterious DAHLIA Exclusive Unequaled Beauty With A",
                //     tags: ["18+", "2022", "124 mins", "98 Match"],
                //     desc: "Well, in my spare time, I like basketball, tennis and Chinese chess. Also English is my favorite.",
                //     cover: "poster_3",
                //     media: "https://qzvd-newblibli3.10cb1c.com/20221029/YjViNmVhNW/104442/1080/hls/decrypt/index.m3u8"
                // },
                // {
                //     id: 20,
                //     title: "Natsu Igarashi",
                //     tags: ["18+", "2022", "120 mins", "98 Match"],
                //     desc: "A Female Employee Who Has Been Harassed By Sticky Boobs 5 Times",
                //     cover: "poster_4",
                //     media: "https://qzvd-newblibli3.10cb1c.com/20221029/M2U3NzJlND/104417/1080/hls/decrypt/index.m3u8"
                // },
                // {
                //     id: 21,
                //     title: "Alex Coal, Jewelz Blu, Harlowe Blue and Val Steele",
                //     tags: ["18+", "2022", "76 mins", "98 Match"],
                //     desc: "Enjoy this compilation of the hottest goth babes getting it on! Featuring goth babes such as Alex Coal, Harlowe Blue, Jewelz Blu, Val Steele, and more!",
                //     cover: "poster_5",
                //     media: "https://qzvd-newblibli3.10cb1c.com/20221028/YmJiNjU0MD/144720/720/hls/decrypt/index.m3u8"
                // }
            ],
            "3": [
                {
                    id: 17,
                    title: "Molly RedWolf, Cherry Acid and Zirael Rem",
                    tags: ["18+", "2022", "45 mins", "98 Match"],
                    desc: "Molly, Cherry and Zirael are three gorgeous anime babes eager to fuck each other! The hottest threesome ensues as they keep themselves busy with each other’s pussies!",
                    cover: "poster_1",
                    media: "https://qzvd-newblibli3.10cb1c.com/20221028/YzFmYWI2NT/144621/720/hls/decrypt/index.m3u8"
                },
                {
                    id: 18,
                    title: "MILF Gets Grocery Store Dick",
                    tags: ["18+", "2022", "30 mins", "98 Match"],
                    desc: "Jimmy Michaels is stocking shelves at the grocery store when one of his Lil Humper buddies points out stacked MILF Ashlyn Peaks and her big melons. ",
                    cover: "poster_2",
                    media: "https://qzvd-newblibli3.10cb1c.com/20221029/MWQ0NzFlZW/104619/1080/hls/decrypt/index.m3u8"
                },
                // {
                //     id: 19,
                //     title: "Amazing Newcomer…?",
                //     tags: ["18+", "2022", "124 mins", "98 Match"],
                //     desc: "A Mysterious DAHLIA Exclusive Unequaled Beauty With A",
                //     cover: "poster_3",
                //     media: "https://qzvd-newblibli3.10cb1c.com/20221029/YjViNmVhNW/104442/1080/hls/decrypt/index.m3u8"
                // },
                // {
                //     id: 20,
                //     title: "Natsu Igarashi",
                //     tags: ["18+", "2022", "120 mins", "98 Match"],
                //     desc: "A Female Employee Who Has Been Harassed By Sticky Boobs 5 Times",
                //     cover: "poster_4",
                //     media: "https://qzvd-newblibli3.10cb1c.com/20221029/M2U3NzJlND/104417/1080/hls/decrypt/index.m3u8"
                // },
                // {
                //     id: 21,
                //     title: "Alex Coal, Jewelz Blu, Harlowe Blue and Val Steele",
                //     tags: ["18+", "2022", "76 mins", "98 Match"],
                //     desc: "Enjoy this compilation of the hottest goth babes getting it on! Featuring goth babes such as Alex Coal, Harlowe Blue, Jewelz Blu, Val Steele, and more!",
                //     cover: "poster_5",
                //     media: "https://qzvd-newblibli3.10cb1c.com/20221028/YmJiNjU0MD/144720/720/hls/decrypt/index.m3u8"
                // }
            ]
        }
        this.hotData = [
                {
                    id: 1,
                    name: "Breaking Bad boy EP1",
                    imgurl: "hot1",
                },
                {
                    id: 9,
                    name: "Taylor Rain Is A Naughty Nymph",
                    imgurl: "hot9",
                },
                {
                    id: 14,
                    name: "Skanky Ethnic Bitch",
                    imgurl: "hot14",
                },
                {
                    id: 16,
                    name: "Ellen Enjoys A Tropical Climate And A Big Black Dick That Fucks Every Hole",
                    imgurl: "hot16",
                },
                {
                    id: 18,
                    name: "MILF Gets Grocery Store Dick",
                    imgurl: "hot18",
                },
        ]
    }

    initOptions(videoUrl, autoplay, muted, loop = false){
        const options = {
            autoplay: autoplay, // 如果true,浏览器准备好时开始播放。
            muted: muted, // 默认情况下将会消除任何音频。
            loop: loop, // 导致视频一结束就重新开始。
            preload: 'auto', // auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',  //汉化
            fluid: true, // 当true时，播放器将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [{
                type: 'application/x-mpegURL',
                src: videoUrl  //视频播放地址
            }],
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
            errorDisplay: false,  //错误显示
            posterImage: false,  //视频的预览海报图片显示
            bigPlayButton: false,  //视频中间的播放按钮显示
            textTrackDisplay: false
        };

        return options;
    };
    shortInitOptions(videoUrl){
        const options = {
            controls: true, // 是否显示控制条
            preload: 'auto', // 自动加载资源
            autoplay: false,//自动播放
            fluid: true, // 自适应宽高
            muted: true, // 是否静音
            loop: true,
            controlBar: { // 设置控制条组件
                /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
                children: [
                    {name: 'progressControl'}, // 播放进度条
                ]
            },
            inactivityTimeout: false, //自动隐藏控制栏
            sources: [{
                type: 'application/x-mpegURL',
                src: videoUrl  //视频播放地址
            }],
        };
        return options;
    };
    checkSignInHandler(forms){
        if(!forms.email){
            return i18n.t('unils.1');
        } else if (!forms.pwd){
            return i18n.t('unils.2');
        } else {
            return "success";
        }
    };

    checkSignUpHandler(forms){

        const valid_map = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;

        if(!forms.email){
            return i18n.t('unils.1');
        } else if (!valid_map.test(forms.email)){
            return i18n.t('unils.3');
        } else if (!forms.pwd){
            return i18n.t('unils.2');
        }  else if (!forms.code){
            return i18n.t('unils.4');
        }  else if (forms.pwd.length < 6 || forms.pwd.length > 12){
            return i18n.t('unils.5');
        } else {
            return "success";
        }
    };

    checkEditEmailHandler(forms){
        const valid_map = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
        if(!forms.email){
            return i18n.t('unils.1');
        } else if (!valid_map.test(forms.email)){
            return i18n.t('unils.3');
        } else {
            return "success";
        }
    };

    checkEditPasswordnext(forms){
        const valid_map = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
        if(!forms.email){
            return i18n.t('unils.1');
        } else if (!valid_map.test(forms.email)){
            return i18n.t('unils.3');
        }  else if (!forms.code){
            return i18n.t('unils.4');
        }  else {
            return "success";
        }
    };
    checkEditPasswordSteptwo(forms){
        if (!forms.pwd){
            return i18n.t('unils.7');
        } else if (forms.pwd.length < 6 || forms.pwd.length > 12){
            return i18n.t('unils.5');
        } else if (forms.pwd !== forms.repassword){
            return i18n.t('unils.8');
        } else {
            return "success";
        }
    };

    checkEditPasswordHandler(forms){
        if(!forms.pwd){
            return i18n.t('unils.6');
        } else if (!forms.newpwd){
            return i18n.t('unils.7');
        } else if (forms.newpwd.length < 6 || forms.newpwd.length > 12){
            return i18n.t('unils.5');
        } else if (forms.newpwd !== forms.password_confirmation){
            return i18n.t('unils.8');
        } else {
            return "success";
        }
    };

    compare(key){
        return function(obj1, obj2){
            let val1 = obj1[key];
            let val2 = obj2[key];

            if(val2 < val1){
                return 1;
            } else if(val2 > val1){
                return -1;
            } else{
                return 0;
            }
        }
    }
}

export const Utils = new UtilClass();
