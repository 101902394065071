const cn = {
    header: {
        "1": "请输入您要查找的内容",
        "2": "搜索",
        "3": "简体中文",
        "03": "中文",
        "4": "英 文",
        "5": "中 文",
        "6": "日 文",
        "Home": "主页",
        "Login": "登录",
        "Register": "注册",
        "REG": "注册",
        "AllMovies": "电影",
        "Catalog": "电视剧",
        "CastList": "演员列表",
        "FilmCompanies": "片商",
        "Director": "导演列表",
        "changePassword": "我的个人中心",
        "Movierecord": "观影记录",
        "signout": "退出",
        "about": "『AOFLIX』是为爱而创造的独立AV拍摄工作室，凝聚了对性生活的热爱与创造，结合特有剧情的创作精神，不断开创出属于国际AV市场的独特性，并将众多AV剧集推向市场推向国际化。",
        "Cooperation": "合作平台",
        "contactus": "联系我们",
        "search": "搜索内容不能为空",
        "Cancel": "取消",
        "searchResults": "搜索结果",
        "TERMS": "条款和条件",
        "Privacy": "隐私政策",
        "Payment": "支付信息",
        "Membership": "会员资格和计费",
        "Information": "信息",
        "ReportIssue": "报告问题",
        "RefundPolicy": "退款政策",
        "submit": "提交",
        "exclusive": "独家",
    },
    allMovies: {
        "popular": "热度",
        "time": "时间",
        "Allactors": "全部演员",
        "All": "全部",
        "actor": "男演员",
        "actress": "女演员",
        "Allfilmmakers": "全部片商",
        "Alldirector": "全部导演",
        "noMovies": '没有结果符合您的搜索。',
        "recommend": '推荐：',
        "tryKeywords": '尝试不同的关键字',
        "LookingTV": '寻找电影或电视节目？',
        "Trylookingup": '尝试按电影名称、电视节目名称或演员或导演的姓名查找',
        "Trylookingby": '尝试按喜剧、爱情、体育或戏剧等类型查找',
        "aboutMovies": '关于电影',
        "nationality": '国籍',
        "country": '国家',
        "height": '身高',
        "weight": '体重',
        "age": '年龄',
        "Added": '添加成功',
        "loadMore": '载入更多中...',
        "toBottom": '没有更多了',
        "Free": '免费',
        "All2": "全部地区",
        "All3": "全部付费类型",
        "All4": "全部影片",
        "All5": "全部年代",
        "All6": "全部类型",
        "Japan": "日本",
        "America": "美国",
        "Free2": "免费",
        "VIP": "会员",
        "WithSu": "含字幕影片",
        "earlier": "更早",
        "Outdoor": "户外",
        "Voyeur": "偷窥",
        "SM": "捆绑",
        "Anal": "肛交",
        "BigTits": "巨乳",
        "Cosplay": "角色扮演",
        "GroupSex": "群交",
        "Facia": "颜射",
        "Deep": "深喉",
        "Incest": "乱伦",
        "Creampie": "中出",
        "BlackPe": "黑人",
        "AOFLIXexc": "AOFLIX独家",
        "Latest": "按时间",
    },
    mine: {
        "1": "更改密码",
        "2": "检查",
        "3": "请输入原密码",
        "4": "请输入新密码",
        "5": "请输入确认密码",
        "6": "定期更改密码有利于安全",
        "7": "下一步",
        "8": "观看记录",
        "9": "密码",
        "10": "观影记录",
        "11": "上传头像图片大小不能超过 2MB!",
        "12": "账户验证",
        "13": "重置密码",
        "14": "确认",
        "15": "密码已更改",
        "16": "请确认您的密码",
        "17": "忘记密码?",
        "18": "帐号",
        "19": "银行卡",
        "20": "最后观看",
        "21": "会员计划",
        "22": "加入",
        "23": "成为会员享受无限观看",
        "24": "账单明细",
        "25": "到期时间",
        "26": "会员到期前1-3天未取消订阅将自动续订。",
        "27": "退订",
        "28": "更新",
        "29": "未激活",
        "30": "年度",
        "31": "每月",
        "32": "取消订阅成功",
        "33": "您确定要取消订阅吗？",
        "34": "默认",
        "35": "管理",
        "36": "解除绑定",
        "37": "您的会员资格",
        "38": "会员类型",
        "39": "会员到期时间",
        "40": " 会员资格 ",
        "41": " 每年",
        "42": "日期",
        "43": "计划",
        "44": "服务周期",
        "45": "支付方式",
        "46": "总金额",
        "47": "没有",
        "48": "添加银行卡",
        "49": "请输入内容",
        "50": " 每月",
        "51": "你确定要设置",
        "52": "为默认银行卡？",
        "53": "请输入验证码",
        "54": "请输入您收到的验证码",
        "55": "发送验证码",
        "56": "倒计时： ",
        "57": "请填写正确的验证码",
        "58": "验证码只能是数字",
        "59": "添加借记卡/信用卡",
        "60": "我们不存储用户的任何付款信息",
        "61": "支持银行",
        "62": "姓",
        "63": "名",
        "64": "卡号",
        "65": "有效期",
        "66": "电话号码",
        "67": "可选的",
        "68": "姓不能为空",
        "69": "姓氏只允许字母和数字",
        "70": "姓氏的长度不能超过 50 个字符",
        "71": "名字不能为空",
        "72": "名字只允许字母和数字",
        "73": "名字的长度不能超过 50 个字符",
        "74": "请输入信用卡号码",
        "75": "信用卡号只允许数字",
        "76": "信用卡号长度为13-19位",
        "77": "请输入卡片安全码",
        "78": "卡安全码只允许数字",
        "79": "卡片安全码长度为3-4位",
        "80": "卡片安全码长度为3-4位",
        "81": "电话号码只允许数字",
        "82": "请选择一个有效的月份/年份",
        "83": "有效期格式不正确",
        "84": "付款日期",
        "85": "银行卡",
        "86": "会员计划",
        "87": "加入会员计划",
        "88": "每日",
        "89": "填充的内容不能是空的",
    },
    serial: {
        "1": "视频时长",
        "2": "发布年份",
        "3": "电影公司",
        "4": "演员",
        "5": "导演",
        "6": "更多类似视频",
        "7": "其他剧集",
        "8": "幕后",
        "9": "分钟",
        "10": "信息不能为空",
        "11": "回到您上次观看的时间 ",
        "12": "拍摄地",
        "13": "番号",
    },
    login: {
        "1": "登录账户",
        "2": "电子邮件",
        "3": "密码",
        "4": "登录",
        "5": "没有账户",
        "6": "现在注册",
        "7": "注册",
        "8": "输入代码",
        "9": "发送代码",
        "10": "注册",
        "11": "请不要给我做广告和报价",
        "12": "已经有账户",
        "13": "去登录",
        "14": "请输入您的邮箱",
        "15": "请输入6~12位密码",
        "16": "输入您的电子邮件收到的代码",
        "17": "注册成功",
        "18": "全球加速，何时何地看电影畅通无阻.",
        "19": "海量成人视频，最专业的成人网站.",
        "20": "看电影无广告，只给你最想要的.",
        "21": "享受",
        "22": "重新获取",
        "23": "发送中",
        "24": "绑定邮箱",
        "25": "下一步",
        "26": "登录失败",
        "27": "其他方式登录"
    },
    home: {
        "1": "播放",
        "2": "查看详情",
        "3": "近期浏览",
        "4": "AOFlix推荐",
        "5": "最新上架",
        "6": "分钟",
        "7": "最热门的视频",
        "8": "",
        "9": "加载中，请稍后..."
    },
    account: {
        "1": "欢迎来到污剧社",
        "2": "登录成功",
        "3": "修改成功",
        "4": "发送成功",
        "5": "退出成功",
        "6": "请重新登录",
        "7": "请先登录",
    },
    unils: {
        "1": "请输入用户名",
        "2": "请输入密码",
        "3": "请检查邮箱格式",
        "4": "请输入code",
        "5": "请输入6-12位的密码",
        "6": "请输入旧密码",
        "7": "请输入新密码",
        "8": "两次输入的密码不一致",
    },
    member: {
        "1": "选择适合你的套餐",
        "2": "全球加速",
        "3": "海量成人影片",
        "4": "免除任何广告",
        "5": "月",
        "6": "年",
        "7": "选择支付方式",
        "8": "您的付款是加密的，而且您可以随时自由切换您的付款方式。",
        "9": "当前",
        "10": "+ 添加银行卡",
        "11": "您同意",
        "12": "《支付条款信息》",
        "13": "《隐私政策协议》",
        "14": "《条款和条件》",
        "15": "通过勾选下面的方框来 AOFlix。",
        "16": "在会员资格到期之前，AOFlix将自动更新您的订阅，并从您提供的备份付款方式中收费，直到您取消。为了停止向你的备用支付方式进一步付款，你可以随时取消。",
        "17": "点击【账户】下的【取消订阅】即可结束订阅。",
        "18": "我已确认",
        "19": "返回",
        "20": "确定",
        "21": "请先确认许可协议",
        "22": "查看条款",
        "23": "请先获取钱包地址",
        "24": "复制成功",
        "25": "钱包地址有效期",
        "26": "复制",
        "27": "点击获取",
        "28": "警告:",
        "29": "请在规定时间内往上述地址转入：",
        "30": "为了您的资金安全，请务必保证转账金额完全正确。",
        "31": "请转账正确的金额：",
        "32": "为了您的资金安全，请务必在上述规定时间内完成转账。",
        "33": "请联系我们，如果您有任何疑问：",
        "34": "天",
        "35": "天",
        "36": "去支付",
        "37": "请点击下方按钮处理您的付款",
        "38": "立省",
        "39": "原价",
        "40": "操作速度太快，请先休息一下！",
        "41": "此地址只接受USDT ERC-20协议充值，存入任何其他货币或协议会造成资金损失并不可追回。如果存款金额不符合充值要求，则充值不会到账。",
        "42": "此地址只接受USDT TRC-20协议充值，存入任何其他货币或协议会造成资金损失并不可追回。如果存款金额不符合充值要求，则充值不会到账。",
        "43": "技术支持：",
        "44": "兑换码",
        "45": "兑换",
        "46": "兑换说明",
        "47": "如果您从专用渠道购买了 AOFlix VIP 并收到了产品兑换码，请在此处使用兑换码激活。 激活成功后，您将收到对应的产品地址，可在电脑/手机渠道无障碍使用。",
        "48": "提示: ",
        "49": "您需要先完成用户登录，然后才能激活兑换码。",
        "50": "技术支持: ",
        "51": "请输入您的兑换码",
        "52": "请先输入兑换码",
        "53": "兑换成功",
        "54": "仅接受USDT TRC-20协议充值，其他币种或协议充值造成资金损失概不负责。",
        "55": "完全访问",
        "56": "仅流式传输",
        "57": "一次性支付",
        "58": "加入计划",
        "59": "您的订阅包含",
        "60": "获得会员资格，享受无限制观看",
        "61": "每月更新",
        "62": "部以上独家影片",
        "63": "支持访问",
        "64": "影片",
        "65": "独家",
        "66": "原创",
        "67": "个性化体验",
        "68": "兼容任何设备",
        "69": "手机、电脑、平板电脑",
        "70": "登录/注册",
        "71": "登录账号，免费看电影",
        "72": "会员到期后会自动续费，您可以选择取消订阅来避免扣费",
        "73": "已取消订阅",
        "74": "取消订阅",
        "75": "取消订阅后",
        "76": "仍然可在",
        "77": "前享有会员特权",
        "78": "残忍取消",
        "79": "再想想",
        "80": "订单确认",
        "81": "会员套餐",
        "82": "订单金额",
        "83": "汇率换算",
        "84": "现价",
        "85": "合计",
        "86": "确认支付",
        "87": "完成",
        "88": "账单地址",
        "89": "详细地址",
        "90": "城市",
        "91": "省份",
        "92": "邮编",
        "93": "国家",
        "94": "信用卡信息",
        "95": "生成订单失败",
    },
    modify: {
        "1": "套餐变更",
        "2": "高清（720p）在任何手机、平板电脑、电脑或电视上，都可以观看。",
        "3": "套餐变更后会在下一个会员到期日自动生效。",
        "4": "您选择了相同的套餐!",
        "5": "当前套餐",
        "6": "套餐",
        "7": "每",
        "8": "变更的套餐",
        "9": "取消",
        "10": "新套餐开始于："
    },
    join: {
        "1": "加入会员计划以观看会员视频。",
        "2": "立即加入"
    },
    aoflix:{
        "1": "最近更新",
        "2": "精选视频",
        "3": "模特",
        "4": "参与拍摄",
    }
}

export default cn;