import axios from '@/utils/interceptor'

export default {
    //获取站点列表
    getSiteList: function (payload = {}) {
        return axios.get("/api/lan/all", { ...payload });
    },

    // 注发送邮箱验证码
    accountSendRegemail: function (payload) {
        return axios.post("/api/user/send/email", { ...payload });
    },

    // 检查注册邮箱
    accountCheckRegemail: function (payload) {
        return axios.post("/api/register/check", { ...payload });
    },

    // 注册
    accountSignUp: function (payload) {
        return axios.post("/api/user/reg", { ...payload });
    },

    // 登录
    accountSignIn: function (payload) {
        return axios.post("/api/user/login", { ...payload });
    },

    // // 上报基础数据
    // submitBasicInfo: function (payload = {}) {
    //     return axios.get("/api/user/info", { ...payload });
    // },

    // 上报影片数据
    submitMovieDetail: function (payload = {}) {
        return axios.post("/api/movie/detail", { ...payload });
    },

    // 获取个人信息接口
    fetchUserInfo: function (payload = {}) {
        return axios.get("/api/user/info", { ...payload });
    },

    // 修改邮箱
    editEmail: function (payload = {}) {
        return axios.post("/api/auth/user/editmail", { ...payload });
    },

    // 修改密码
    editPassword: function (payload = {}) {
        return axios.post("/api/user/pwd", { ...payload });
    },

    // 用邮箱修改密码
    editEmailback: function (payload = {}) {
        return axios.post("/api/user/mail/back", { ...payload });
    },

    // 用户观看列表
    movieWatchlist: function (payload = {}) {
        return axios.get("/api/user/movie/watch/list",{ params: { ...payload } });
    },

    // 图片上传
    imgUpload: function (payload = {}) {
        let config = {
            headers: { 'Content-Type': 'multipart/form-data' }
        }
        return axios.post("/api/user/img", payload, config);
    },
    // 修改用户信息
    sendUserInfo: function (payload = {}) {
        return axios.post("/api/user/update", { ...payload });
    },
    //获取用户会员信息
    getMemberStatus: function (payload = {}) {
        return axios.get("/api/pay/get/member", { ...payload });
    },
    // 更换/取消订阅
    unSubscribe: function (payload = {}) {
        return axios.post("/api/pay/subscribe", { ...payload });
    },
    //获取银行卡列表
    getBanklist: function (payload = {}) {
        return axios.get("/api/pay/get/user/bank/list", { ...payload });
    },











    // 获取观看进度
    getTimeUpdateFn: function(payload = {}){
        return axios.get("/api/user/movie/get/watch/time", { params: { ...payload } });
    },
    // 设置观看进度
    sendtimeUpdate: function(payload = {}){
        return axios.post("/api/user/movie/set/watch/time", {...payload});
    },
    //获取影片推荐/类似信息
    getMovieepisode: function (payload = {}) {
        return axios.get("/api/movie/episode", { params: { ...payload } });
    },
    //获取影片推荐/类似信息
    getMoviesimilar: function (payload = {}) {
        return axios.get("/api/movie/similar", { params: { ...payload } });
    },
    // 播放影片
    getMovieplay: function (payload = {}) {
        return axios.get("/api/movie/play", { params: { ...payload } });
    },
    // 获取影片详情
    getMovieDetails: function (payload = {}) {
        return axios.get("/api/movie/details", { params: { ...payload } });
    },
    // 获取全部影片
    getMovieAll: function(payload = {}){
        return axios.get("/api/movie/all", {params: {...payload}});
    },
    // 全部演员信息
    getMovieInfoAll: function (payload = {}) {
        return axios.get("/api/movie/info/all", { params: { ...payload } });
    },
    // 添加到播放列表
    addMovieWatch: function(payload = {}){
        return axios.post("/api/user/movie/watch", {...payload});
    },
    // 影片演员详细信息
    getMovieInfoDetails: function (payload = {}) {
        return axios.get("/api/movie/info/details", { params: { ...payload } });
    },
    // 获取影片列表-演员
    getMovieInfoDetailsList: function (payload = {}) {
        return axios.get("/api/movie/info/list", { params: { ...payload } });
    },
    // 搜索影片
    getMovieSearch: function (payload = {}) {
        return axios.get("/api/movie/search", { params: { ...payload } });
    },
    // 获取footer数据
    getFooterData: function (payload = {}) {
        return axios.get("/api/conf/info", { params: { ...payload } });
    },
    // 获取银行列表
    getBankList: function (payload = {}) {
        return axios.get("/api/pay/get/bank/list", { params: { ...payload } });
    },
    // 添加银行卡
    getAddBank: function (payload = {}) {
        return axios.post("/api/pay/buy/member", { ...payload } );
    },
    // 设置默认银行卡
    getBankDefault: function (payload = {}) {
        return axios.post("/api/pay/bank/default", { ...payload } );
    },
    // 发送解绑邮箱验证码
    getSendLiftedEmail: function (payload = {}) {
        return axios.post("/api/pay/send/lifted/email", { ...payload } );
    },
    // 解除银行卡绑定
    getBankLifted: function (payload = {}) {
        return axios.post("/api/pay/bank/lifted", { ...payload } );
    },
    // 获取用户账单信息
    getBillinfo: function (payload = {}) {
        return axios.get("/api/pay/get/bill", { params: { ...payload } });
    },
    // 第三方登录绑定邮箱
    bindEmail: function (payload = {}) {
        return axios.post("/api/worth/ment/reg", { ...payload });
    },
    // 统计 观看数据上报
    // 统计 发起支付上报
    getDataNikkatsu: function (payload = {}) {
        return axios.get("/api/count/data/nikkatsu", { params: { ...payload } });
    },
    // 获取影片列表-电影-电视剧
    getMovieList: function (payload = {}) {
        return axios.get("/api/movie/list", { params: { ...payload } });
    },
    // 统计 来源信息
    postlinkSpread: function (payload = {}) {
        return axios.post("/api/user/link/spread", { ...payload });
    },
    // 获取问题列表
    getFeedbackQA: function (payload = {}) {
        return axios.get("/api/feedback/questions", { params: { ...payload } });
    },
    // 提交反馈数据
    submitFeedback: function (payload = {}) {
        return axios.post("/api/feedback/submit", { ...payload });
    },
    // 获取影片筛选条件
    getHomefilter: function (payload = {}) {
        return axios.get("/api/home/filter", { params: { ...payload } });
    },
    // 获取充值引导页图片配置
    getRechargeguide: function (payload = {}) {
        return axios.get("/api/conf/recharge/guide/list", { params: { ...payload } });
    },
    // 获取导航栏配置
    getNavinfo: function (payload = {}) {
        return axios.get("/api/conf/nav/info", { params: { ...payload } });
    },
    // 获取短视频
    getShortlist: function (payload = {}) {
        return axios.get("/api/movie/short/list", { params: { ...payload } });
    },
}
