import axios from 'axios';

import store from '@/store'

import router from '@/router'

import { Message } from 'element-ui';

import i18n from '../assets/i18n/';

axios.defaults.timeout = 180000;

axios.interceptors.request.use(config => {

    const locale = window.localStorage.getItem("locale") || '1';

    const token = localStorage.getItem('token');

    if(token) config.headers['sign-token'] = token;

    config.headers['site'] = locale;

    config.baseURL = process.env.NODE_ENV_BASEURL;

    return config;

}, error => {

    return Promise.reject(error);

});


axios.interceptors.response.use(response => {

    const { data, status} = response;

    if(status != 200) Message.error(response.statusText);

    if(data.code == 401){

        Message.success(i18n.t('account.6'));

        store.commit("SET_COVER_STATUS", true);

        store.commit("SET_SIGIN_STATUS", true);

    } else if(data.code == 999){
        // token无效
        store.commit("SET_TOKEN", null);
        window.localStorage.clear();
        store.commit("SET_SIGIN_STATUS", true);
    }
    else if(data.code == 1000){
        store.commit("SET_TOKEN", null);
        window.localStorage.clear();
        store.commit("SET_SIGIN_STATUS", true);
    }
    else{
        if(data.code != 200  && data.code != 1015 && data.code != 1000) Message.error(data.msg);
    }


    return response.data;

}, error => {

    console.log(error)

});

export default axios;


