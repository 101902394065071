<template>
    <section class="sign-up sign" :class="registrationSuccess ? 'signups':''">
        <div v-if="registrationSuccess" class="registrationSuccess">
          <h4>{{ $t('login.17') }}</h4>
          <el-checkbox v-model="checked1" class="checkbox">{{ $t('login.18') }}.</el-checkbox>
          <el-checkbox v-model="checked2" class="checkbox">{{ $t('login.19') }}.</el-checkbox>
          <el-checkbox v-model="checked3" class="checkbox">{{ $t('login.20') }}.</el-checkbox>
          <div @click="SetSigupSuccessFn(true)" class="enjoy">{{ $t('login.21') }}</div>
        </div>
        <div v-else>
          <div class="login-bg">
            <i @click="SetSigupStatusFn(false)" class="close"></i>
          </div>
          <form>
            <div class="form-group">
              <input type="text" v-model="payload.email" class="form-control"
                     @click="error = ''" :placeholder="$t('login.2')"
              />
            </div>
            <div class="form-group">
              <input type="text" v-model="payload.code" class="form-control"
                     @click="error = ''" :placeholder="$t('login.16')"
              />
              <span v-show="show" @click="SendEmailHandler"><i></i>{{ sdTxt }}</span>
              <span v-show="!show" class="count"><i></i>{{count}}s</span>
            </div>
            <div class="form-group">
              <input type="password" v-model="payload.pwd" class="form-control"
                     @click="error = ''" :placeholder="$t('login.15')"
              />
            </div>
            <div class="bmtan clearfix bmtan-enroll">
              <span class="error pull-left">{{ error }}</span>
              <a class="submit-btn pull-right" @click="signUpHandler">{{ $t('login.10') }}</a>
            </div>
            <div class="sf-title">
              <span class="line"></span>
              <span class="text">{{ $t('login.27') }}</span>
            </div>
            <div class="sf-cont">
              <img src="@/assets/images/common/google.png" @click="othSignInHandler(2,'google')" />
              <img src="@/assets/images/common/facebook.png" @click="othSignInHandler(1,'facebook')" />
              <img src="@/assets/images/common/twitter.png" @click="othSignInHandler(3,'twitter')" />
            </div>
            <div class="go-signin clearfix">
              <p>
                <span class="line"></span>
                {{ $t('login.12') }}<span @click="signInfromSignUpHandler">{{ $t('login.13') }}</span>
              </p>
            </div>
          </form>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name : "SignUp",
    data(){
        return {
            payload: {
                email: "", pwd: "", code: "",push_mail:1
            },
            error: "",
            checked: false,
            checked1: true,
            checked2: true,
            checked3: true,
            registrationSuccess:false,
            show: true,
            count: '',
            timer: null,
            sdTxt: this.$t('login.9')
        }
    },
    computed: {
      ...mapState({
        mine: (state) => state.account.mine,
        userMember: (state) => state.home.userMember
      })
    },
    mounted(){
      //绑定事件
      window.othSignInHandler = this.othSignInHandler;
    },
    methods: {
        ...mapActions({
            SetLoadStatusFn: "SetLoadStatusFn",
            SetSiginStatusFn: "SetSiginStatusFn",
            SetSigupStatusFn: "SetSigupStatusFn",
            AccountCheckRegemailFn: "AccountCheckRegemailFn",
            AccountSignUpFn: "AccountSignUpFn",
            SendEmailFn: "SendEmailFn",
            FetchUserInfoFn: "FetchUserInfoFn",
            FetchUserMemberFn: "FetchUserMemberFn",
            PostlinkSpreadFn: "PostlinkSpreadFn",
            FetchMentLoginFn: "FetchMentLoginFn",
        }),
        async SendEmailHandler(){
          const res = this.$utils.checkEditEmailHandler(this.payload);
          if(res === "success"){
            const {email} = this.payload;
            const status = await this.SendEmailAxiosHandler({email});
          }else{
            this.error = res;
          }
        },
        async signUpHandler(){
            const res = this.$utils.checkSignUpHandler(this.payload);
            if(res === "success"){
                if (this.checked){
                  this.payload.push_mail = 2
                }else{
                  this.payload.push_mail = 1
                }
                const status = await this.signUpAxiosHandler(this.payload);
            }else{
                this.error = res;
            }
        },
        signUpAxiosHandler(payload){
          this.SetLoadStatusFn({loadStatus: true});
            this.error = "";
            setTimeout( async () => {
                const result = await this.AccountSignUpFn(payload);
                if(result == 200)
                this.SetSigupStatusFn(false);
                this.SetLoadStatusFn({loadStatus: false});
                if(result == 200){
                  const mnResult = await this.FetchUserMemberFn();
                  const mnResult2 = await this.FetchUserInfoFn();
                  const params = { link_spread: window.localStorage.getItem("whereFrom") || ''};
                  const Spread = this.PostlinkSpreadFn(params);
                  if(this.userMember){
                      gtag("event", "page_view", {
                          page_title: "sign_up_success",
                          page_location: "https://" + window.location.host + "/sign_up_success"
                      });
                      gtag("event", "sign_up", {
                        user_id: this.userMember.uid,
                        ct_signuptime:'1',
                        user_properties: {
                          ct_user_id: this.userMember.uid,
                          ct_isvip: this.userMember.isVip == 1 ? true : false,
                          ct_method: '直接'
                        }
                      });
                      _paq.push(['setUserId', this.userMember.uid]);     //用户id，1、2、3…
                      _paq.push(['setCustomDimension', 1, this.userMember.isVip == 1 ? true : false]);  // //当前状态是否是会员：true/false
                      _paq.push(['trackEvent',
                        '注册登录',
                        '注册成功',
                        '直接']);   //注册/登录方式：直接、google、facebook、twitter
                    }
                }
            },1000);
        },
        SendEmailAxiosHandler(payload){
          this.sdTxt = this.$t('login.23');
          this.error = "";
          const TIME_COUNT = 60;
          setTimeout( async () => {
            const result = await this.SendEmailFn(payload);
            if(result == 200){
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.sdTxt = this.$t('login.9');
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000)
              }
            }
          },1000);
        },
        signInfromSignUpHandler(){
            this.SetSigupStatusFn(false);
            this.SetSiginStatusFn(true);
        },
        SetSigupSuccessFn(state){
          this.registrationSuccess =false
          this.SetSigupStatusFn(false);
          this.SetLoadStatusFn({loadStatus: false});
        },
        async othSignInHandler(param,type){
          const params = { type: param};
          const result = await this.FetchMentLoginFn(params);
          gtag("event", "ct_click", {
            ct_click_type:  "第三方关联跳转",
            ct_click_name:  type
          });
          if(result.code == 200){
            const { url } = result.data;
            setTimeout( async () => {
              window.location.href = url;
            },1000);
          }
        }
    }
}
</script>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: #FF0000 !important;
  border-color: #FF0000 !important;
  width: 14px !important;
  height: 14px !important;
}
.el-checkbox__inner::after{
  left: 8px;
  top:4px;
}
</style>
