const jp = {
    header: {
        "1": "検索内容を入力してください",
        "2": "検索",
        "3": "日本語",
        "03": "日本語",
        "4": "英語",
        "5": "中国語",
        "6": "日本語",
        "Home": "ホーム",
        "Login": "ログイン",
        "Register": "登録",
        "REG": "登録",
        "AllMovies": "すべての動画",
        "Drama": "ドラマ",
        "CastList": "キャストリスト",
        "FilmCompanies": "メーカー",
        "Director": "監督リスト",
        "changePassword": "アカウント",
        "Movierecord": "視聴履歴",
        "signout": "サインアウト",
        "about": "「AOFLIX」は愛のために作られたAV製作スタジオです。性に対する情熱と創造性を込めて、独創性のある内容を作り上げ、グローバルのAVマーケットにおいて常にオリジナルティーを追求し",
        "Cooperation": "提携",
        "contactus": "お問い合わせ",
        "search": "検索内容を空欄にしないでください",
        "Cancel": "キャンセル",
        "searchResults": "の検索結果",
        "TERMS": "規約と条件",
        "Privacy": "プライバシーポリシー",
        "Payment": "支払情報",
    },
    allMovies: {
        "popular": "人気",
        "time": "時間",
        "Allactors": "すべての俳優",
        "All": "全部",
        "actor": "俳優",
        "actress": "女優",
        "Allfilmmakers": "全ての映画会社",
        "Alldirector": "全ての監督",
        "noMovies": "検索内容に一致する項目はありません。",
        "recommend": "おすすめ:",
        "tryKeywords": "他のキーワード",
        "LookingTV": "映画やテレビ番組を探す？",
        "Trylookingup": "映画名、番組名、俳優や監督の名前で検索してみてください",
        "Trylookingby": "コメディ、ロマンス、スポーツ、ドラマなどのジャンルで検索してみてください",
        "aboutMovies": "映画について",
        "nationality": "国籍",
        "country": "国",
        "height": "身長",
        "weight": "体重",
        "age": "年齢",
        "追加": "追加しました",
        "loadMore": "もっと見る",
        "toBottom": "一番下へ",
        "Free": "無料",
        "All2": "全て",
        "All3": "全て",
        "All4": "全て",
        "All5": "全て",
        "All6": "全て",
        "Japan": "日本",
        "America": "アメリカ",
        "Free2": "無料",
        "VIP": "VIP",
        "WithSu": "字幕付き",
        "earlier": "ついさっき",
        "Outdoor": "公共(18+)",
        "Voyeur": "",
        "SM": "ボンデージ",
        "Anal": "アナル",
        "BigTits": "巨乳",
        "Cosplay": "コスプレ",
        "GroupSex": "輪姦",
        "Facia": "ザーメン",
        "Deep": "ディープスロート",
        "Incest": "近親相姦",
        "Creampie": "クリームパイ",
        "BlackPe": "黒い人",
        "AOFLIXexc": "AOFLIX",
        "Latest": "最新"
    },
    mine: {
        "1": "パスワードの変更",
        "2": "チェック",
        "3": "元のパスワードを入力してください",
        "4": "新しいパスワードを入力してください",
        "5": "確認用パスワードを入力してください",
        "6": "セキュリティ安全のため、パスワードを定期的に変更しましょう",
        "7": "次へ",
        "8": "視聴履歴を見る",
        "9": "パスワード",
        "10": "視聴履歴",
        "11": "アップロードするアバター画像は2MBを超えないようにしてください!",
        "12": "アカウント認証",
        "13": "パスワードのリセット",
        "14": "確認",
        "15": "パスワードが変更されました",
        "16": "パスワードを確認してください",
        "17": "パスワードをお忘れですか？",
        "18": "口座番号",
        "19": "バンクカード",
        "20": "最後に見た",
        "21": "メンバーシップ プログラム",
        "22": "参加する",
        "23": "会員登録で見放題",
        "24": "支払明細",
        "25": "有効期限",
        "26": "メンバーシップの有効期限が切れる 1 ～ 3 日前にサブスクリプションがキャンセルされない場合、サブスクリプションは自動的に更新されます。",
        "27": "退会する",
        "28": "更新する",
        "29": "不活化した",
        "30": "年",
        "31": "毎月",
        "32": "登録を解除しました",
        "33": "登録を解除してもよろしいですか？",
        "34": "默ル认",
        "35": "管理",
        "36": "解放する",
        "37": "あなたのメンバーシップ",
        "38": "会員種別",
        "39": "会員有効期限",
        "40": "メンバーシップ",
        "41": " 1年当たり",
        "42": "日にち",
        "43": "予定",
        "44": "サービスサイクル",
        "45": "支払方法",
        "46": "一時金",
        "47": "いいえ",
        "48": "銀行カードを追加",
        "49": "内容を入力してください",
        "50": " 1月当たり",
        "51": "本当に設定しますか",
        "52": "デフォルトの銀行カードとして?",
        "53": "確認コードを入力してください",
        "54": "で受け取った確認コードを入力してください",
        "55": "確認コードを送信する",
        "56": "秒読み：",
        "57": "正しい確認コードを入力してください",
        "58": "確認コードには数字のみを使用できます",
        "59": "デビット/クレジット カードを追加する",
        "60": "ユーザーの支払い情報は一切保存しません",
        "61": "選択する",
        "62": "苗字",
        "63": "ファーストネーム",
        "64": "カード番号",
        "65": "有効期限",
        "66": "電話番号",
        "67": "オプション",
        "68": "姓を空にすることはできません",
        "69": "lastName には文字と数字のみを使用できます",
        "70": "lastName の長さは 50 文字を超えてはなりません",
        "71": "firstName を空にすることはできません",
        "72": "firstName には文字と数字のみを使用できます",
        "73": "firstName の長さは 50 文字を超えてはなりません",
        "74": "クレジットカード番号を入力してください",
        "75": "クレジット カード番号は数字のみ使用できます",
        "76": "クレジット カードの長さは 13 ～ 19 文字です",
        "77": "カードのセキュリティコードを入力してください",
        "78": "カードのセキュリティ コードは数字のみ使用できます",
        "79": "カードのセキュリティ コードの長さは 3 ～ 4 桁です",
        "80": "カードのセキュリティ コードの長さは 3 ～ 4 桁です",
        "81": "PhoneNumber は数字のみを使用できます",
        "82": "有効な月/年を選択してください",
        "83": "有効期限の形式が正しくありません",
        "84": "支払期日"
    },
    serial: {
        "1": "ビデオの長さ",
        "2": "公開年",
        "3": "映画会社",
        "4": "俳優",
        "5": "監督",
        "6": "似たビデオのおすすめ",
        "7": "他のエピソード",
        "8": "ビハインド・ザ・シーン",
        "9": "分",
        "10": "メッセージを空欄にすることはできません",
        "11": "前回視聴した時間に戻る",
    },
    login: {
        "1": "アカウントにログイン",
        "2": "Eメール",
        "3": "パスワード",
        "4": "ログイン",
        "5": "アカウントなし",
        "6": "今すぐ登録",
        "7": "登録",
        "8": "コードを入力してください",
        "9": "コードを送信する",
        "10": "登録",
        "11": "CMやイベントのお知らせはご遠慮ください",
        "12": "すでにアカウントを持っている",
        "13": "ログインに進む",
        "14": "Eメールを入力してください",
        "15": "6~12桁のパスワードを入力してください",
        "16": "メールで受け取ったコードを入力してください",
        "17": "登録が完了しました",
        "18": "いつでもどこでも、映画を手軽に観られる,",
        "19": "豊富なアダルト動画、最も専門的なアダルトサイト",
        "20": "広告なしで映画を楽しめる",
        "21": "どうぞ",
        "22": "再取得",
        "23": "再取得",
        "24": "メールボックスをバインド",
        "25": "登録",
        "26": "ログインに失敗しました",
        "27": "三者"
    },
    home: {
        "1": "放映",
        "2": "詳細を見る",
        "3": "最近見たもの",
        "4": "AOFlixのおすすめ",
        "5": "新しく公開",
        "6": "分",
        "7": "最も人気のある動画",
        "8": "",
        "9": "読み込み中"
    },
    account: {
        "1": "「AOFLIX」へようこそ",
        "2": "ログインしました",
        "3": "変更が完了しました",
        "4": "送信が完了しました",
        "5": "ログアウトしました",
        "6": "もう一度ログインしてください",
        "7": "最初にログインしてください",
    },
    unils: {
        "1": "ユーザー名を入力してください",
        "2": "パスワードを入力してください",
        "3": "メールフォーマットをご確認ください",
        "4": "コードを入力してください",
        "5": "6～12桁のパスワードを入力してください",
        "6": "元のパスワードを入力してください",
        "7": "新しいパスワードを入力してください",
        "8": "2回入力されたパスワードが一致しません。",
    },
    member: {
        "1": "あなたにぴったりのパッケージをお選びください",
        "2": "グローバルアクセラレーション",
        "3": "多数の成人向け作品",
        "4": "広告宣伝費免除",
        "5": "月",
        "6": "年",
        "7": "支払い方法の選択",
        "8": "お客様のお支払いは暗号化され、いつでも自由にお支払い方法を変更することができます。",
        "9": "現在",
        "10": "+ カードを追加",
        "11": "あなたは同意する",
        "12": "《お客様はAOFlixの支払条件情報》",
        "13": "《プライバシーポリシー同意書》",
        "14": "《および利用規約に同意したことになります》",
        "15": "以下の該当するボックスをチェックして、AOFlix の.",
        "16": "メンバーシップの有効期限が切れる前に、AOFlixは自動的にサブスクリプションを更新し、お客様が提供したバックアップ支払方法から、お客様がキャンセルするまで課金されます。 バックアップ支払方法に対する今後の支払いを停止するために、お客様はいつでも解約することができます。",
        "17": "アカウント]の[購読の取り消し]をクリックすると、購読が終了します。",
        "18": "を意識しています。",
        "19": "閉じる",
        "20": "確認事項",
        "21": "最初にライセンス契約を確認してください",
        "22": "規約を見る",
        "23": "最初にウォレットアドレスを取得してください",
        "24": "正常にコピー",
        "25": "ウォレットアドレスの有効期間",
        "26": "コピー",
        "27": "入手",
        "28": "警告:",
        "29": "指定時間内に上記住所へお振込みください。",
        "30": "資金の安全のため、送金金額が完全に正しいことを確認してください。",
        "31": "送金金額は次のように正確に入力してください：",
        "32": "資金の安全のため、上記の特定の時間内に送金してください。",
        "33": "ご不明な点がございましたら、お問い合わせください："
    },
    modify: {
        "1": "パッケージの変更",
        "2": "スマホ、タブレット、パソコン、テレビでHD（720p）を楽しむことができます。",
        "3": "パッケージの変更は、次回の会員有効期限から自動的に適用されます。",
        "4": "同じパッケージを選択しました!",
        "5": "現在の会員プラン",
        "6": "メンバーシップ",
        "7": "あたり",
        "8": "入会プラン変更",
        "9": "キャンセル",
        "10": "「新企画スタート"
    },
    join: {
        "1": "メンバーシップ プログラムに参加して、メンバーシップ ビデオをご覧ください。",
        "2": "今すぐ参加"
    }
}

export default jp;