<template>
        <div class="adlist-bar" v-if="sendconfigList.length>0">
              <div class="adlist-text">
                  {{ adlistItem.copywriting }}
              </div>
              <div class="adlist-link">
<!--                <router-link :to="{path: adlistItem.url}">-->
<!--                  {{ adlistItem.button_text }}-->
<!--                </router-link>-->
                <router-link :to="{ path: adlistItem.url }">
                  {{ adlistItem.button_text }}
                </router-link>
              </div>
        </div>
</template>

<script>

import { mapState, mapActions } from "vuex";


export default {
    name: "NoData",
    data() {
        return {
          adlistItem: "",
        };
    },
    props: {
      sendconfigList: { type: Array },
    },
    computed: {
      ...mapState({
          configList: (state) => state.home.configList,
        }),
    },
    mounted(){

    },
    beforeDestroy() {

    },
    methods: {

    },
    watch: {
      sendconfigList() {
        let lists = this.sendconfigList || this.configList
        for(const item of lists) {
          if (item.locationInfo.parameter=="index-top"){
            this.adlistItem = item
          }
        }
      },
    },
    components: {

    },
}
</script>

<style lang="scss" scoped>
.adlist-bar {
    background: #45AA43;
    width:100%;
    height: 48px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .adlist-text{
      font-weight: 700;
      font-size: 21px;
      color: #F9F9F9;
    }
    .adlist-link{
      a{
        display: block;
        border: 2px solid #FFFFFF;
        font-weight: 700;
        font-size: 22px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #F9F9F9;
        padding: 5px 20px;
        cursor: pointer;
      }
    }
}

@media screen and (max-width:768px){
  .adlist-bar {
    .adlist-text{
      width: 70%;
      word-wrap: break-word;
      padding-left: 3%;
      font-size: 14px;
    }
    .adlist-link{
      width: 30%;
      display: flex;
      a{
        font-size: 14px;
        padding: 5px;
      }
    }
  }
}
</style>