<template>
    <section class="serials">
        <div class="serial-box container">
            <h4>全部剧集</h4>
            <div class="wrap">                
                <ul :style="{'width': computedContainer + 'px'}" class="clearfix" v-if="videos.length > 2">
                    <li class="pull-left" v-for="(item, index) in videos"
                        :key="index"
                        @click="fetchSerialsHandler(index)"
                    >                            
                        <em class="vip"></em>
                        <i></i>
                        <img :src="require(`@/assets/images/cover/${item.cover}.jpg`)" />
                        <span>第{{ index + 1 }}集</span>
                        <div class="shadow-box"></div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name : "Serials",
    props: {
        videos: {
            type: Array, default : () => []
        }
    },
    methods: {
        ...mapActions({
            SetCoverStatusFn: "SetCoverStatusFn",
            SetSiginStatusFn: "SetSiginStatusFn",
        }),
        fetchSerialsHandler(index){
            const { sid } = this.$route.params;
            const token = window.localStorage.getItem("token");

            if(!token){
                window.localStorage.setItem('path', `/serial/${sid}?pid=${index + 1}`);
                this.SetCoverStatusFn(true);
                this.SetSiginStatusFn(true);
            }else{             
                this.$router.push(`/serial/${sid}?pid=${index + 1}`)
            }
        }
    },
    computed: { 
        computedContainer(){            

            const width = document.body.clientWidth > 768 ?
                190 * (this.videos.length) : 
                140 * (this.videos.length) ;

            return width;

        }
    }
}

</script>