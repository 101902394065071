import axios from '@/utils/interceptor';
import router from '@/router/index';

export default {

    // 获取用户最新观看
    fetchHomeSeen : function (payload = {}) {
        return axios.get("/api/movie/home",{ params: { ...payload } });
    },

    // UMovie列表
    fetchUmovieList: function (payload = {}) {
        return axios.get("/api/movie/home",{ params: { ...payload } });
    },

    // 最新上架
    fetchNewMovieList: function (payload = {}) {
        return axios.get("/api/movie/home",{ params: { ...payload } });
    },

    // 首页获取影片详情
    fetchHmovieDetails: function (payload = {}) {
        return axios.get("/api/movie/details/tips", { params: { ...payload } });
    },

    // 浮窗影片简讯
    fetchMovieSimple: function (payload = {}) {
        return axios.get("/api/movie/simple", { params: { ...payload } });
    },

    // 获取banner数据
    fetchBannerList: function(payload = {}){
        return axios.get("/api/banner/list", {params: {...payload }})
    },

    // 获取套餐类型
    fetchComboList: function(payload = {}){
        return axios.get("/api/pay/get/combo", {params: {...payload }})
    },

    // 获取套餐支付方式
    fetchClientList: function(payload = {}){
        return axios.get("/api/pay/get/client", {params: {...payload }})
    },

    // 获取银行卡列表
    fetchBankCardList: function(payload = {}){
        return axios.get("/api/pay/get/user/bank/list", {params: {...payload }})
    },

    // 开通会员
    payMember: function (payload = {}) {
        return axios.post("/api/pay/member", { ...payload });
    },

    // 获取用户会员信息
    fetchUserMember: function(payload = {}){
        return axios.get("/api/pay/get/member", {params: {...payload }})
    },

    // 更换会员套餐
    switchMemcombo: function(payload = {}) {
        return axios.post("/api/pay/subscribe", { ...payload });
    },

    // 获取栏目列表
    fetchBarLists: function(payload = {}) {
        return axios.get("/api/home/tab/bar", {params: {...payload }});
    },

    // 获取栏目对应影片列表
    fetchBarMovie: function(payload = {}) {
        return axios.get("/api/home/movie/tab/bar", {params: {...payload }});
    },

    // 请求三方登录接口
    fetchMentLogin: function(payload = {}) {
        return axios.get("/api/worth/ment/login/url", {params: {...payload }});
    },

    // USDT钱包地址/topPay/GCash
    fetchPayurl: function(payload = {}) {
        return axios.post("/api/pay/create/url", { ...payload });
    },

    //H5首页影片推荐
    fetchMobHomeMovie: function(payload = {}) {
        return axios.get("api/h5/home/recomm/movie/list", {params: {...payload }});
    },

    // 兑换VIP
    fetchExchangeMember: function(payload = {}) {
        return axios.post("/api/user/exchange/member", { ...payload });
    },
    // 获取广告配置
    fetchConfadlist: function(payload = {}) {
        return axios.get("api/conf/ad/list", {params: {...payload }});
    },
    // 获取 aoflix 推荐相关
    fetchrecommendlist: function(payload = {}) {
        return axios.get("api/conf/aoflix/recommend", {params: {...payload }});
    },
    // 获取 aoflix 推荐分页
    fetchrecommendpagelist: function(payload = {}) {
        return axios.get("api/conf/aoflix/recommend/page", {params: {...payload }});
    },
    // 获取二维码下载
    fetchversionupdate: function(payload = {}) {
        return axios.get("/api/version/update", {params: {...payload }});
    },
}
