import api from "@/services/api"
import router from '@/router/index';
import { Message } from 'element-ui';
import i18n from '../assets/i18n/';

const state = () => ({
    moviesAll: [],
    trailerStatus: "simple",
    addPlayList: null,
    directorList:[], // 导演
    actorDetails: {}, // 演员详细信息
    actorDetailsList: [], // 演员推荐影片
    filmList:[],
    filmDetails: {}, // 片商详细
    filmDetailsList: [], // 片商推荐影片
    directorDetails: {}, // 导演详细
    directorDetailsList: [], // 导演推荐影片
    searchVal:[],   // 搜索影片
    footerData: {},
    termsData: {},  // 条款与条件
    bankList: [],  // 银行列表
    Homefilter: [],
});
const mutations = {
    SET_HOME_FILTER(state, data) {
      state.Homefilter = data;
    },
    SET_MOVIES_ALL(state, data) {
        state.moviesAll = data;
    },
    SET_TRAILER_STATUS(state, data) {
        state.trailerStatus = data;
    },
    SET_ADDPLAY_LIST(state, data) {
        state.addPlayList = data;
    },
    SET_DIRECTOR_LIST(state, data) {
        state.directorList = data;
    },
    SET_ACTOR_DETAILS(state, data) {
        state.actorDetails = data;
    },
    SET_ACTOR_DETAILS_LIST(state, data) {
        state.actorDetailsList = data;
    },
    SET_FILM_LIST(state, data){
        state.filmList = data;
    },
    SET_FILM_DETAILS(state, data) {
        state.filmDetails = data;
    },
    SET_FILM_DETAILS_LIST(state, data) {
        state.filmDetailsList = data;
    },
    SET_DIRECTOR_DETAILS(state, data) {
        state.directorDetails = data;
    },
    SET_DIRECTOR_DETAILS_LIST(state, data) {
        state.directorDetailsList = data;
    },
    SET_SEARCH_VAL(state, data){
        state.searchVal = data;
    },
    SET_FOOTER_DATA(state, data){
        state.footerData = data;
    },
    SET_TERMS_DATA(state, data){
        state.termsData = data;
    },
    SET_BANK_LIST(state, data){
        state.bankList = data;
    },
};

const actions = {
    // 获取全部影片
    getMoviesAllFn({commit}, params){
        return api.getMovieAll(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_MOVIES_ALL', data || {});
                    // console.log('全部电影',data)
                }
                return res;
            })
    },
    SetTrailerStatusFn({commit}, data){
        commit("SET_TRAILER_STATUS", data);
    },
    // 添加到播放列表
    getAddPlayListFn({commit}, params){
        return api.addMovieWatch(params)
            .then(res => {
                const { code, data, msg } = res;
                if(code == 200){
                    Message.success(i18n.t('allMovies.Added'));
                }
                return code;
            })
    },
    // 播放
    getMovieInfoPlaysFn({commit}, params){
        return api.getMovieplay(params)
            .then(res => {
                const { code, data, msg } = res;
                return res;
            })
    },
    // 导演列表
    getDirectorListFn({commit}, params){
        return api.getMovieInfoAll(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_DIRECTOR_LIST', data || [])
                }
                return res;
            })
    },
    // 演员详细信息
    getMovieInfoDetailsFn({commit}, params){
        return api.getMovieInfoDetails(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_ACTOR_DETAILS', data)
                }
                return code;
            })
    },
    // 获取影片列表-演员
    getMovieInfoDetailsListFn({commit}, params){
        return api.getMovieInfoDetailsList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_ACTOR_DETAILS_LIST', data || [])
                }
                return res;
            })
    },
    // 片商
    getfilmListFn({commit}, params){
        return api.getMovieInfoAll(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_FILM_LIST', data || [])
                }
                return res;
            })
    },
    // 片商详细信息
    getFilmInfoDetailsFn({commit}, params){
        return api.getMovieInfoDetails(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_FILM_DETAILS', data)
                }
                return code;
            })
    },
    // 片商推荐影片
    getFilmInfoDetailsListFn({commit}, params){
        return api.getMovieInfoDetailsList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_FILM_DETAILS_LIST', data || [])
                }
                return res;
            })
    },
    // 导演详细信息
    getDirectorInfoDetailsFn({commit}, params){
        return api.getMovieInfoDetails(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_DIRECTOR_DETAILS', data)
                }
                return code;
            })
    },
    // 导演推荐影片
    getDirectorInfoDetailsListFn({commit}, params){
        return api.getMovieInfoDetailsList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_DIRECTOR_DETAILS_LIST', data || [])
                }
                return code;
            })
    },
    // 搜索影片
    getMovieSearchFn({commit}, params){
        return api.getMovieSearch(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_SEARCH_VAL', data || [])
                }
                return res;
            })
    },
    // 获取footer数据
    getFooterDataFn({commit}, params){
        return api.getFooterData(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    // let data2 = JSON.parse(JSON.stringify(data));
                    commit('SET_FOOTER_DATA', data || {})
                }
                return res;
            })
    },
    // 隐私政策
    getTermsDataFn({commit}, params){
        return api.getFooterData(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_TERMS_DATA', data || {})
                }
                return res;
            })
    },
    // 获取银行列表
    getBankList({commit}, params){
        return api.getBankList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_BANK_LIST', data.list || [])
                }
                return res;
            })
    },
    // 添加银行卡
    getAddBankFn({commit}, params){
        return api.getAddBank(params)
            .then(res => {
                const { code, data } = res;
                return res;
            })
    },
    // 设置默认银行卡
    getBankDefaultFn({commit}, params){
        return api.getBankDefault(params)
            .then(res => {
                const { code, data } = res;
                return res;
            })
    },
    // 发送解绑邮箱验证码
    getSendLiftedEmailFn({commit}, params){
        return api.getSendLiftedEmail(params)
            .then(res => {
                const { code, data } = res;
                return res;
            })
    },
    // 解除银行卡绑定
    getBankLiftedFn({commit}, params){
        return api.getBankLifted(params)
            .then(res => {
                const { code, data } = res;
                return res;
            })
    },
    // 统计 发起支付上报
    getDataNikkatsuNineFn({commit}, params){
      return api.getDataNikkatsu(params)
        .then(res => {
          const { code, data } = res;
          return res;
        })
    },
    // 获取影片列表-电影-电视剧
    getMovieListFn({commit}, params){
        return api.getMovieList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    let arr = data ? data.list : [];
                    commit('SET_MOVIES_ALL', arr || []);
                }
                return res;
            })
    },
    // 获取影片筛选条件
    getHomefilterFn({commit}, params){
          return api.getHomefilter(params)
            .then(res => {
              const { code, data } = res;
              if(code == 200){
                let arr = data ? data.list : [];
                commit('SET_HOME_FILTER', arr || []);
              }
              return res;
            })
    },
};

export default {
    state,
    mutations,
    actions
}
