<template>
  <section class="post-main">
    <div class="post">
         <div class="title">Please note</div>
         <div class="content">
           <p>AOFlix.com contains explicit sex scenes. You must be of legal age in your locality and agree that you want to view such material.</p>

           <p> Misrepresenting your age in order to gain access to the Site may violate your country, state or federal laws. By accessing the Site, you hereby affirm that you are not in violation of the Forgery Clause and that you are at least 18 years of age (or the legal age of majority in the location where you access the Site). You also understand that all of the actors and actresses appearing on the Site are 18 years of age or older, are performing commercially with the consent of the actors, and that the plot is purely fictional.</p>

           <p>You represent that, based on your familiarity with these standards and laws in your location, you will not violate any such standards or laws by requesting and receiving any adult material on the Site.</p>
           <p>You understand that you shall hold the Site harmless from any liability related to your request, receipt and possession of material on the Site.</p>

           <p> Are you 18 years of age or older? (or the legal age of majority in the jurisdiction where you access the Site)</p>
         </div>
         <div class="btn-bottom">
           <div class="left">
             <p>1</p>
             <a href="javascript:void(0);" class="btn confirm" @click="confirmEnter()">
               Yes, I'm 18 years old
             </a>
           </div>
           <div class="right">
             <p>1</p>
             <a href="javascript:void(0);" class="btn" @click="notEighteen()">
               No, I'm not of legal age
             </a>
           </div>
         </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Announcement",
  mounted() {
    _paq.push(['trackEvent', '新访客弹窗', '新访客弹窗']);
  },
  methods: {
    confirmEnter(){
      gtag("event", "ct_click_yes18");
      _paq.push(['trackEvent', '新访客弹窗', '是18岁']);
      this.$emit("confirmEnter", true);
      try{
        if(AndroidClient){
          AndroidClient.goLoginPage()
        }
      }catch(err){
        console.log(err);
      }
    },
    notEighteen(){
      gtag("event", "ct_click_not18");
      _paq.push(['trackEvent', '新访客弹窗', '否18岁']);
      setTimeout( async () => {
        window.location.href = 'https://www.google.com/';
      },1000);
    }
  }
};
</script>

<style scoped>
.post .content::-webkit-scrollbar {
  width: 6px;
  height: 1px;

}
.post .content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #5D6061;
}
.post .content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: #333;
}
.post-main {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 8888;
}
.post {
  height: auto;
  width: 960px;
  position: relative;
  margin: 0 auto;
}
.post .title{
  font-weight: 700;
  font-size: 20px;
  text-transform: capitalize;
  color: #FFFFFF;
  height: 66px;
  line-height:66px;;
  background: #000000;
  text-indent: 20px;
}
.post .content{
  background: #212121;
  padding: 1px 20px 1px 20px;
}
.post .content p{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  margin:20px 0;
}
.post .btn-bottom{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  margin: auto;
}
.post .btn-bottom .right,
.post .btn-bottom .left{
  text-align: center;
}
.post .btn-bottom p{
  visibility: hidden;
  line-height: 40px;
  color: #FFFFFF;
}
.post .btn-bottom a{
  width: 236px;
  height: 40px;
  background: #212121;
  color: #FFFFFF;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.post .btn-bottom a.confirm{
  background: #FF0000;
}
@-webkit-keyframes bottom-top {
  0% {
    height: 0;
    opacity: 0;
  }
  50% {
    height: 30px;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
@keyframes bottom-top {
  0% {
    height: 0;
    opacity: 0;
  }
  50% {
    height: 30px;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
@media screen and (max-width:1360px){
  .post {
    width: 80%;
    height:90%;
  }
  .post .content{
    height: 50%;
    overflow-y: auto;
  }
}
@media screen and (max-width:768px) {
  .post {
    width: 86%;
    height:90%;
  }
  .post .title{
    line-height: 40px;
    height: auto;
    font-size: 16px;
    text-align: center;
    text-indent: inherit;
  }
  .post .content{
    padding: 1px 10px 1px 10px;
    height: 60%;
    overflow-y: auto;
  }
  .post .content p{
    font-size: 14px;
  }
  .post .btn-bottom{
    display: block;
    width: 100%;
  }
  .post .btn-bottom p{
    color: rgba(255, 255, 255, 0.6);
  }
  .post .btn-bottom a{
    width: 100%;
    height: 48px;
  }
}
@media screen and (max-width:375px){
  .post {
    margin-top: -10%;
  }
}
</style>
