const en = {
    header: {
        "1": "Please Enter",
        "2": "Search",
        "3": "ENGLISH",
        "03": "En",
        "4": "English",
        "5": "Chinese",
        "6": "Japanese",
        "Home": "HOME",
        "Login": "Login",
        "Register": "REGISTER",
        "REG": "REG",
        "AllMovies": "VIDEOS",
        "Catalog": "CATALOG",
        "CastList": "MODELS",
        "FilmCompanies": "FILM COMPANIES",
        "Director": "DIRECTOR",
        "changePassword": "Membership ",
        "Movierecord": "Movie Record",
        "signout": "Logout",
        "about": '"AOFLIX" is an AV filming studio of created for love. It has passionate about sexual life and combines the unique plot. It also keep expand international AV market',
        "Cooperation": "Cooperation Platform",
        "contactus": "CONTACT US",
        "search": "Search content cannot be empty",
        "Cancel": "Cancel",
        "searchResults": "SEARCH RESULTS",
        "TERMS": "TERMS & CONDITIONS",
        "Privacy": "PRIVACY POLICY",
        "Payment": "PAYMENT INFORMATION",
        "Membership": "MEMBERSHIP AND BILLING",
        "Information": "Information",
        "ReportIssue": "REPORT ISSUE",
        "RefundPolicy": "REFUND POLICY",
        "submit": "Submit",
        "exclusive": "EXCLUSIVE",
    },
    allMovies: {
        "popular": "Popular",
        "time": "Time",
        "Allactors": "All Actors",
        "All": "All",
        "actor": "Actor",
        "actress": "Actress",
        "Allfilmmakers": "All Filmmakers",
        "Alldirector": "All Director",
        "noMovies": 'No results matched for your search?',
        "recommend": 'Recommend:',
        "tryKeywords": 'Try different keywords',
        "LookingTV": 'Looking for a movie or series?',
        "Trylookingup": 'Try looking up by movie title, series title, or the name of an actor or director',
        "Trylookingby": 'Try looking by genre such as comedy, romance, sports or drama',
        "aboutMovies": 'About Movies',
        "nationality": 'Nationality',
        "country": 'Country',
        "height": 'Height',
        "weight": 'Weight',
        "age": 'Age',
        "Added": 'Added Successfully',
        "loadMore": 'Loading more...',
        "toBottom": 'No more',
        "Free": 'FREE',
        "All2": "All",
        "All3": "All",
        "All4": "All",
        "All5": "All",
        "All6": "All",
        "Japan": "Japan",
        "America": "America",
        "Free2": "Free",
        "VIP": "VIP",
        "WithSu": "With Subtitles",
        "earlier": "Earlier",
        "Outdoor": "Public(18+)",
        "Voyeur": "",
        "SM": "Bondage",
        "Anal": "Anal",
        "BigTits": "Big Tits",
        "Cosplay": "Cosplay",
        "GroupSex": "Gangbang/Orgy",
        "Facia": "Cumshot",
        "Deep": "",
        "Incest": "Step Fantasy",
        "Creampie": "Creampie",
        "BlackPe": "Black",
        "AOFLIXexc": "AOFLIX",
        "Latest": "Latest",
    },
    mine: {
        "1": "Change the Password",
        "2": "Check",
        "3": "Please enter original password",
        "4": "Please enter a new password",
        "5": "Please enter confirm password",
        "6": "Regularly changing passwords is good for security",
        "7": "Next",
        "8": "History",
        "9": "Password",
        "10": "History",
        "11": "The size of the uploaded avatar image cannot exceed 2MB!",
        "12": "Account Verification",
        "13": "Reset Password",
        "14": "Confirm",
        "15": "Password has been changed",
        "16": "Please confirm your password",
        "17": "Forget it?",
        "18": "ACCOUNT",
        "19": "BANK",
        "20": "Last Watched",
        "21": "VIP",
        "22": "JOIN",
        "23": "Get membership to enjoy unlimited viewing",
        "24": "Billing",
        "25": "End time",
        "26": "The subscription will automatically renew if it is not canceled 1-3 days before the membership expiration date.",
        "27": "Unsubscribe",
        "28": "Renew",
        "29": "INACTIVE",
        "30": "ANNUAL",
        "31": "MONTHLY",
        "32": "Successfully unsubscribed",
        "33": "Are you sure you want to unsubscribe?",
        "34": "Default",
        "35": "Manage",
        "36": "Unbind",
        "37": "Your Membership",
        "38": "Type Of Membership",
        "39": "Membership Expiration Time",
        "40": " Membership ",
        "41": " Per Year",
        "42": "Date",
        "43": "Plan",
        "44": "Service Cycle",
        "45": "Payment Method",
        "46": "Total Amount",
        "47": "Nothing",
        "48": "Add Bank Card",
        "49": "Please Enter",
        "50": " Per Month",
        "51": "Are you sure to set the",
        "52": "card as the default debit bank card?",
        "53": "Please enter verification code",
        "54": "Please enter the verification code you received at",
        "55": "Send the verification code",
        "56": "Countdown: ",
        "57": "Please fill in the correct verification code",
        "58": "Verification code can only be numbers",
        "59": "Enter your payment details",
        "60": "We do not store any of your information",
        "61": "Support Bank",
        "62": "Last Name",
        "63": "First Name",
        "64": "Card Number",
        "65": "Expiration Date",
        "66": "Phone Number",
        "67": "optional",
        "68": "Last Name cannot be empty",
        "69": "Last Name only allows letters and numbers",
        "70": "The length of Last Name must not exceed 50 characters",
        "71": "First Name cannot be empty",
        "72": "First Name only allows letters and numbers",
        "73": "The length of First Name must not exceed 50 characters",
        "74": "Please enter the credit card number",
        "75": "Credit card numbers only allow digits",
        "76": "Credit card length between 13 and 19 characters",
        "77": "Please enter the card security code",
        "78": "Card security code only allows numbers",
        "79": "Card security code length is 3-4 digits",
        "80": "Card security code length is 3-4 digits",
        "81": "Phone number only allows numbers",
        "82": "Please select a valid month/year",
        "83": "Incorrect format of expiry date",
        "84": "Payment Date",
        "85": "Credit Card",
        "86": "Membership Plan",
        "87": "Enrolled in the membership program",
        "88": "DAILY",
        "89": "The filled content cannot be empty",
    },
    serial: {
        "1": "Video Duration",
        "2": "Release Year",
        "3": "Producer",
        "4": "Actor",
        "5": "Director",
        "6": "More Similar Films",
        "7": "Other Episodes",
        "8": "Behind The Scenes",
        "9": "mins",
        "10": "Information cannot be empty",
        "11": "Go back to your last watched time ",
        "12": "Filming location",
        "13": "Serial Number",
    },
    login: {
        "1": "Login",
        "2": "E-mail",
        "3": "Password",
        "4": "Log in",
        "5": "Don't have an account yet?",
        "6": "Go Register",
        "7": "Registration",
        "8": "Verification Code",
        "9": "Send",
        "10": "Verify",
        "11": "Please do not advertise and offer me",
        "12": "Have an account?",
        "13": "Go Login",
        "14": "Please input your email",
        "15": "Please enter a 6~12 digit password",
        "16": "Enter code",
        "17": "Registration Success",
        "18": "Global acceleration, when and where to watch movies unimpeded.",
        "19": "Massive adult videos, the most professional adult website.",
        "20": "Watch movies without ads, only give you what you want most.",
        "21": "enjoy",
        "22": "Re-send",
        "23": "Sending...",
        "24": "Enter email",
        "25": "Next",
        "26": "Login Failed",
        "27": "or",
    },
    home: {
        "1": "Play",
        "2": "More Info",
        "3": "RECENTLY VIEWED",
        "4": "AOFLIX EXCLUSIVE",
        "5": "NEW ARRIVAL",
        "6": "mins",
        "7": "Hottest Videos",
        "8": "",
        "9": "loading more..."
    },
    account: {
        "1": "Welcome to AOFLIX",
        "2": "Login Successful",
        "3": "Modified Successfully",
        "4": "Send Successfully",
        "5": "Exit Successfully",
        "6": "Please login again",
        "7": "Please login first",
    },
    unils: {
        "1": "Please enter username",
        "2": "Please enter your password",
        "3": "Please check the email format",
        "4": "Please enter code",
        "5": "Please enter a 6-12 digit password",
        "6": "Please enter the old password",
        "7": "Please enter a new password",
        "8": "The passwords entered twice do not match",
    },
    member: {
        "1": "CHOOSE THE PACKAGE THAT SUITS YOU",
        "2": "Global acceleration, free movie viewing times and locations.",
        "3": "The most professional adult website, with enormous adult films.",
        "4": "Watch movies without commercials and only receive what you want.",
        "5": "month",
        "6": "year",
        "7": "CHOOSING A PAYMENT METHOD",
        "8": "Your payments are encrypted, and you are always free to switch your payment method.",
        "9": "Current",
        "10": "+ ADD BANK CARD",
        "11": "By checking the checkbox below, you agree to",
        "12": "《Payment Terms Information》",
        "13": "《Privacy Policy Agreement》",
        "14": "《Terms and Conditions》",
        "15": "of AOFlix by checking the appropriate box below.",
        "16": "Prior to the membership expiring, AOFlix will automatically renew your subscription and charge the backup payment method you supplied until you cancel. To stop further payments from being made to your backup payment method, you can cancel at any time. If you decide to terminate your membership during the gift period, it won't take effect until your AOFlix balance is completely used up.",
        "17": "Click [Cancel Subscription] under [Account] to end your subscription.",
        "18": "I HAVE CONFIREMED",
        "19": "Go Back",
        "20": "Save",
        "21": "Please confirm the license agreement first",
        "22": "View TERMS",
        "23": "Please get the wallet address first",
        "24": "copy successfully",
        "25": "Token will be expired in ",
        "26": "COPY",
        "27": "CLICK TO GET THE TOKEN",
        "28": "WARNING:",
        "29": "For your money safety,please ensure that the transfer amount is exact the same as : ",
        "30": "to the token above within the time.",
        "31": "Please transfer  amount is exactly corrects as：",
        "32": "For the safety of your funds, please make sure that the transfer within the specific time above.",
        "33": "Please contact us if you have any questions：",
        "34": "day",
        "35": "days",
        "36": "GO PAY",
        "37": "Please Click Below Button to Process Your Payment",
        "38": "OFF",
        "39": "Original Price",
        "40": "Operation is too fast, please take a break first！",
        "41": "This address only accepts USDT ERC-20 protocol recharge. Depositing any other currency or protocol will cause a loss of funds and cannot be recovered. If the deposit amount does not meet the recharge requirements, the recharge will not arrive.",
        "42": "This address only accepts USDT TRC-20 protocol recharge. Depositing any other currency or protocol will cause a loss of funds and cannot be recovered. If the deposit amount does not meet the recharge requirements, the recharge will not arrive.",
        "43": "Supports：",
        "44": "PROMO CODE",
        "45": "EXCHANGE",
        "46": "Activation Instructions",
        "47": "If you purchased AOFlix VIP from a dedicated channel and received a product redemption code, please activate it here with the redemption code. After successful activation, you will receive the corresponding product address, which can be used in pc/mobile channels without barries.",
        "48": "Reminder: ",
        "49": "You need to complete user login before you can activate the redemption code.",
        "50": "Support: ",
        "51": "Pleasse enter the correct promo code",
        "52": "Please input the code first",
        "53": "Successfully",
        "54": "Only USDT TRC-20 protocol is accepted for top-up, other currencies or protocols will result in loss of funds and will not be responsible.",
        "55": "Full Access",
        "56": "Streaming Only",
        "57": "One-time payment of",
        "58": "Join the plan",
        "59": "Your subscription includes",
        "60": "Join the membership plan and enjoy unlimited viewing",
        "61": "More than ",
        "62": " exclusive movies updated every month",
        "63": "Support access to ",
        "64": " movies",
        "65": "Exclusive and ",
        "66": "original",
        "67": " personalized experience",
        "68": "Compatible with any device",
        "69": "Mobile, PC, Tablet",
        "70": "Login / Register",
        "71": "Login account, watch movies for free",
        "72": "Membership will be renewed automatically when it expires, you can choose to cancel your subscription to avoid the deduction of fees",
        "73": "Unsubscribed",
        "74": "Unsubscribe",
        "75": "After unsubscribing",
        "76": "Still available at",
        "77": "Enjoy membership privileges before",
        "78": "Cruel cancellation",
        "79": "Think again",
        "80": "Order Confirmation",
        "81": "Membership Package",
        "82": "Order Amount",
        "83": "Exchange rate conversion",
        "84": "Current Price",
        "85": "Total",
        "86": "Confirmation of payment",
        "87": "Complete",
        "88": "Billing Address",
        "89": "Full address",
        "90": "City",
        "91": "Province",
        "92": "Postal Code",
        "93": "Country",
        "94": "Credit Card Info",
        "95": "Failure to generate an order",
        "96": "Create account, watch movies for free",
        "97": "choose join option",
    },
    modify: {
        "1": "Change Streaming Plan",
        "2": "Good video quality in HD (720p). Watch on any phone, tablet, computer or TV.",
        "3": "The revised plan will automatically go into effect on the following membership expiration date after it has been modified.",
        "4": "same membership!",
        "5": "Current Membership Plan",
        "6": "membership",
        "7": "per",
        "8": "Changed Membership Plan",
        "9": "Cancel",
        "10": "New plan starts on"
    },
    join: {
        "1": "Join the membership program to watch membership videos.",
        "2": "JOIN NOW"
    },
    aoflix:{
        "1": "recently updated",
        "2": "Featured Videos",
        "3": "Models",
        "4": "Participate in filming",
    }
}

export default en;
