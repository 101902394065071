<template>
  <section class="footer1" :class="{'short':pathname }">
    <div class="cont">
      <div class="warp">
        <div class="left">
            <img class="imgwz" src="@/assets/images/common/logo.png" alt="">
            <p class="textleft" v-if=" !!footerData.list" v-html="content"></p>
            <p class="textleft" v-else>{{""}}</p>
        </div>
        <div class="right">
          <p class="title">{{$t('header.contactus')}}</p>
          <ul class="icon_ul">
            <li class="blog" v-if="blogData"><a :href="blogData.val.link"  target="_blank" rel="noreferrer noopener"></a></li>
            <li class="twitter"><a href="https://twitter.com/AoflixOfficial"  target="_blank" rel="noreferrer noopener"></a></li>
             <li class="facebook"><a href="https://www.facebook.com/AOFlixCom" target="_blank" rel="noreferrer noopener" ></a></li>
            <li class="line"><a href="https://instagram.com/aoflix69?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noreferrer noopener" ></a></li>
            <li class="discord"><a href="https://discord.com/invite/CGYGxdhStE " target="_blank" rel="noreferrer noopener" ></a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="title" @click="handlUpFullweb()">{{$t('header.Information')}}<i class="el-icon-arrow-up" :class="isShow? 'reverse':''"></i></div>
    <div class="bottom" :class="isShow? 'active':''">
        <div class="rightText">
          <span class="linkFooter" :class="{'activeItem': activeItem==index}" v-for="(item, index) in navs" :key="index" @click="handleLink(item.routerUrl,index)">
              {{ item.text }}
          </span>
        </div>
    </div>
    <div class="navi">
        <ul class="navH5"  v-if="menuinfo && menuinfo.info" >
          <li v-for = "(item, index) in menuinfo.info.H5" :key="index"  @click="testurl(item.type,index)" v-if="menuinfo.info.H5 && menuinfo.info.H5.length>0" :class="{'active': index == current}">
             <img class="logo " :src="index == current? item.selected_icon : item.no_selected_icon" v-if="item.no_selected_icon">
             <img class="logo " :src="index == current? require(`@/assets/images/footer/aicon${item.type}.png`): require(`@/assets/images/footer/icon${item.type}.png`)" v-else>
              <span>
                  {{item.name}}
              </span>
          </li>
        </ul>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import "./css/WjsFooter.css";
export default {
  name: "WjsFooter",
  data() {
    return {
      content : "",
      isShow: false,
      activeItem:-1,
      linkList:"/terms/privacy/Membership/Dmca/2257",
      menuList:"//exclusive/castlist/short/allmovies",
      navs: [
        { text: this.$t('header.TERMS'), routerUrl: '/terms' }, //  条款与条件
        { text: this.$t('header.Privacy'), routerUrl: '/privacy' },  //  隐私政策
        { text: this.$t('header.Membership'), routerUrl: '/Membership' },  // 支付信息
        { text: 'DMCA', routerUrl: '/DMCA' },  //  隐私政策
        { text: '2257', routerUrl: '/2257' },  // 支付信息
        { text: this.$t('header.ReportIssue'), routerUrl: '/Report' },  //  隐私政策
        { text: this.$t('header.RefundPolicy'), routerUrl: '/Refund' },  // 支付信息
      ],
      current:null,
      pathname:false,
      blogData: undefined
    };
  },
  computed: {
    ...mapState({
        footerData: (state) => JSON.parse(JSON.stringify(state.movies.footerData)),
        menuinfo: (state) => state.account.menuinfo
       }),
  },
  watch: {
    $route(to) {
      if (this.linkList.indexOf(to.path) !== -1) {}else{
        this.activeItem = -1
      }
      if (this.menuList.indexOf(to.path) !== -1) {}else{
        this.current = null
      }
      if (to.path.indexOf('short') !=-1){
        this.pathname = true
      }else{
        this.pathname = false
      }
    }
  },
  async created() {
    if(window.localStorage.getItem("token")){
      await this.FetchUserMemberFn();
    }
  },
  mounted() {
    this.sendFooterData();
    this.getBlogAddress();
    let pathname = window.location.pathname,type
    this.$nextTick(() => {
      setTimeout( async () => {
        switch (true) {
          case (pathname.indexOf("/") !=-1 || pathname.indexOf("/") !=-1):
            type = 1
            break;
          case (pathname.indexOf("allmovies") !=-1):
            type = 2
            break;
          case (pathname.indexOf("castlist") !=-1):
            type = 3
            break;
          case (pathname.indexOf("exclusive") !=-1):
            type = 4
            break;
          case (pathname.indexOf("short") !=-1):
            type = 5
            break;
        }
        for(const [index,item] of this.menuinfo.info.H5.entries()) {
          if (type ==item.type){
            this.current = index
          }
        }

      },2000);
    })
  },
  methods: {
    ...mapActions({
        getFooterDataFn: "getFooterDataFn",
        FetchUserMemberFn:"FetchUserMemberFn",
      }),
      testurl(type,index){
        this.current = index;
        switch (type) {
          case 1:
            type = "/"
            break;
          case 2:
            type = "/allmovies"
            break;
          case 3:
            type = "/castlist"
            break;
          case 4:
            type = "/exclusive"
            break;
          case 5:
            type = "/short"
            break;
        }
        this.$router.push(type).catch(err => {err});
      },
      handleLink(routerUrl,index){
        this.$router.push(routerUrl)
        this.$nextTick(() => {
          setTimeout( async () => {
            this.activeItem = index
          },200);
        })
      },
      handlUpFullweb(){
        this.isShow = !this.isShow;
        let that =document.getElementById("app");
        setTimeout(() => {
          that.scrollTop = 10000;
        },200);
      },
      //   获取数据
      async sendFooterData() {
            const params = {
              type: 1
            }
            const result = await this.getFooterDataFn(params);
            // console.log('footerData', this.footerData);

            if(this.footerData.list && this.footerData.list.val){
              this.content = this.footerData.list.val.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
              .replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&apos;/g, "'");
            }

        },
        async getBlogAddress() {
          const params = {
                type: 15
              }
              const res = await this.getFooterDataFn(params);
              if(res.code == 200 && res.data.list) {
                this.blogData = res.data.list;
              }
        }
      },
    };
</script>
