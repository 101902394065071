<template>
    <section class="vip-box">
        <img width="70%" src="@/assets/images/icons/vip_box.png" />
        <p>恭喜您</p>
        <p>免费获得价值588元的180天VIP</p>
        <a href="javascript:void(0);" class="btn"
            @click="SetVipStatusFn(false)"
        >
            知道了
        </a>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "vip-box",    
    methods: {
        ...mapActions({
            SetVipStatusFn: "SetVipStatusFn",
        }),
    },
}
</script>