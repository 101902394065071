<template>
    <section class="sign-up sign bind-email" :class="registrationSuccess ? 'signups':''">
        <div v-if="registrationSuccess" class="registrationSuccess">
          <div class="login-bg pass-bg">
            <i @click="SetBdemlStatusFn(false)" class="close"></i>
          </div>
          <el-checkbox v-model="checked1" class="checkbox">{{ $t('login.18') }}.</el-checkbox>
          <el-checkbox v-model="checked2" class="checkbox">{{ $t('login.19') }}.</el-checkbox>
          <el-checkbox v-model="checked3" class="checkbox">{{ $t('login.20') }}.</el-checkbox>
          <div @click="SetSigupSuccessFn(true)" class="enjoy">{{ $t('login.21') }}</div>
        </div>
        <div v-else>
          <div class="login-bg pass-bg">
            <i @click="SetBdemlStatusFn(false)" class="close"></i>
          </div>
          <form>
            <div class="form-group">
              <input type="text" v-model="payload.email" class="form-control"
                     @click="error = ''" :placeholder="$t('login.2')"
              />
            </div>
            <div class="form-group">
              <input type="text" v-model="payload.code" class="form-control"
                     @click="error = ''" :placeholder="$t('login.16')"
              />
              <span v-show="show" @click="SendEmailHandler">{{ sdTxt }}<i></i></span>
              <span v-show="!show" class="count">{{count}}s</span>
            </div>
            <div class="form-group">
              <input type="password" v-model="payload.pwd" class="form-control"
                     @click="error = ''" :placeholder="$t('login.15')"
              />
            </div>
            <div class="bmtan clearfix bmtan-enroll">
              <span class="error pull-left">{{ error }}</span>
              <a class="submit-btn pull-right" @click="signUpHandler">{{ $t('login.25') }}</a>
            </div>
          </form>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name : "BindEmail",
    data(){
        return {
            payload: {
                wm_key: "", email: "", pwd: "", code: "",push_mail:1
            },
            error: "",
            checked: false,
            checked1: true,
            checked2: true,
            checked3: true,
            registrationSuccess:false,
            show: true,
            count: '',
            timer: null,
            sdTxt: this.$t('login.9')
        }
    },
    computed: {
      ...mapState({
        mine: (state) => state.account.mine,
        userMember: (state) => state.home.userMember
      })
    },
    mounted(){
      this.payload.wm_key = this.getQueryString('data');
    },
    methods: {
        ...mapActions({
            SetLoadStatusFn: "SetLoadStatusFn",
            SetSigupStatusFn: "SetSigupStatusFn",
            AccountCheckRegemailFn: "AccountCheckRegemailFn",
            AccountSignUpFn: "AccountSignUpFn",
            SendEmailFn: "SendEmailFn",
            FetchUserInfoFn: "FetchUserInfoFn",
            FetchUserMemberFn: "FetchUserMemberFn",
            SetBdemlStatusFn: "SetBdemlStatusFn",
            BindEmailFn: "BindEmailFn",
            PostlinkSpreadFn: "PostlinkSpreadFn",
        }),
        async SendEmailHandler(){
          const res = this.$utils.checkEditEmailHandler(this.payload);
          if(res === "success"){
            const {email} = this.payload;
            const status = await this.SendEmailAxiosHandler({email});
          }else{
            this.error = res;
          }
        },
        async signUpHandler(){
            const res = this.$utils.checkSignUpHandler(this.payload);
            if(res === "success"){
                const status = await this.signUpAxiosHandler(this.payload);
            }else{
                this.error = res;
            }
        },
        signUpAxiosHandler(payload){
           this.SetLoadStatusFn({loadStatus: true});
            this.error = "";
            setTimeout( async () => {
                const result = await this.BindEmailFn(payload);
                if(result == 200) this.registrationSuccess =true
                this.SetLoadStatusFn({loadStatus: false});
                this.SetBdemlStatusFn(false);
                const login_type = this.getQueryString("login_type");
                const source = this.getQueryString('source')
                const params = { link_spread: window.localStorage.getItem("whereFrom") || ''};
                const Spread = this.PostlinkSpreadFn(params);
                let method1;
                if (source == 2){
                  method1 = 'google'
                }else if (source == 1){
                  method1 = 'facebook'
                }else if(source == 3){
                  method1 = 'twitter'
                }
                if(login_type == 4){
                  gtag("event", "sign_up", {
                    user_id: this.userMember.uid,
                    ct_signuptime:'1',
                    user_properties: {
                      ct_user_id: this.userMember.uid,
                      ct_isvip: this.userMember.isVip == 1 ? true : false,
                      ct_method: method1+'手动绑定'
                    }
                  });
                  _paq.push(['setUserId', this.userMember.uid]);     //用户id，1、2、3…
                  _paq.push(['setCustomDimension', 1, this.userMember.isVip == 1 ? true : false]);  // //当前状态是否是会员：true/false
                  _paq.push(['trackEvent',
                    '注册绑定',
                    '绑定成功',
                    method1]);   //注册/登录方式：直接、google、facebook、twitter
                }
                if(result == 200){
                  const mnResult = await this.FetchUserMemberFn();
                }
            },1000);
        },
        SendEmailAxiosHandler(payload){
          this.sdTxt = this.$t('login.23');
          this.error = "";
          const TIME_COUNT = 60;
          setTimeout( async () => {
            const result = await this.SendEmailFn(payload);
            if(result == 200){
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.sdTxt = this.$t('login.9');
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000)
              }
            }
          },1000);
        },
        SetSigupSuccessFn(state){
          this.registrationSuccess =false
          this.SetSigupStatusFn(false);
          this.SetLoadStatusFn({loadStatus: false});
        },
        getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substring(1).match(reg);
            //if (r != null) return decodeURIComponent(r[2]); return null;
            if(r != null) return r[2]; return null;
        }
    }
}
</script>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: #0EAD69;
  border-color: #0EAD69;
  width: 20px;
  height: 20px;
}
.el-checkbox__inner::after{
  left: 8px;
  top:4px;
}
</style>
