import router from "@/router";
import home from "@/services/home"
import { Utils } from "@/utils/utils";
import { Message } from 'element-ui';

const state = () => ({
    homeSeenList: [],
    newList: [],
    homeLists: {
        recently: [],
        umovie: [],
        newlist: []
    },
    hMoviesDetails: {},
    cinemaTag: [],
    swiperButton: true,
    bannerList: [],
    cinemaUrl: [],
    comboList: [],
    clientList: [],
    bankCardList: [],
    userMember: {},
    barList: [],
    mobMovieLists: [],
    firstEnter: false,
    configList: [],
    recommendList: [],
    versionupdate: [],
    recommendrecently: [],
    recommendfeatured: [],
});

const mutations = {
    SET_HSEEN_LIST(state, data) {
        state.homeSeenList = data;
    },
    SET_NEW_LIST(state, data) {
        state.newList = data;
    },
    SET_HOME_LISTS(state, data){
        state.homeLists = {
            ...state.homeLists,
            ...data
        };
    },
    SET_HMOVIES_DETAILS(state, data){
        state.hMoviesDetails = data;
    },
    SET_CINEMA_TAG(state, data){
        state.cinemaTag = data;
    },
    SET_SWIPER_BUTTON(state, data){
        state.swiperButton = data;
    },
    SET_BANNER_LIST(state, data){
        state.bannerList = data;
    },
    SET_CINEMA_URL(state, data){
        state.cinemaUrl = data;
    },
    SET_COMBO_LIST(state, data){
        state.comboList = data;
    },
    SET_CLIENT_LIST(state, data){
        state.clientList = data;
    },
    SET_BANKCARD_LIST(state, data){
        state.bankCardList = data;
    },
    SET_USERMEMBER_INFO(state, data){
        state.userMember = data;
    },
    SET_BAR_LIST(state, data){
        state.barList = data;
    },
    SET_MOB_MOVIE_LIST(state, data){
        state.mobMovieLists = [...state.mobMovieLists, ...data];
    },
    RESET_MOB_MLIST(state, data){
        state.mobMovieLists = data;
    },
    SET_CONFIGLIST(state, data) {
      state.configList = data;
    },
    SET_RECOMMEND(state, data) {
        state.recommendList = data;
    },
    SET_FIRSTENTER_STATUS(state, obj) {
      state.firstEnter = obj;
    },
    SET_VERISION(state, data) {
      state.versionupdate = data;
    },
    SET_RECOMMENDRECENTLY(state, data) {
      state.recommendrecently = data;
    },
    SET_RECOMMENDFEATURED(state, data) {
      state.recommendfeatured = data;
    },
};

const actions = {
    SetFirstEnterFn({commit},data){
      commit("SET_FIRSTENTER_STATUS", data);
    },
    // 获取用户最新观看
    FetchHomeSeenFn({commit}, params){
        return home.fetchHomeSeen(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    const { RecentList } = data;
                    commit('SET_HOME_LISTS', { recently: RecentList });
                }
                return code;
            })
    },
    // UMovie列表
    FetchUmovieListFn({commit}, params){
        return home.fetchUmovieList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    const { UMovieList } = data;
                    commit('SET_HOME_LISTS', { umovie: UMovieList });
                }
                return code;
            })
    },
    // 最新上架
    FetchNewMovieListFn({commit}, params){
        return home.fetchNewMovieList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    const { NewList } = data;
                    commit('SET_HOME_LISTS', { newlist: NewList });
                }
                return code;
            })
    },
    // 首页弹窗视频详情
    FetchHmovieDetailsFn({commit}, params){
        return home.fetchHmovieDetails(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_HMOVIES_DETAILS', data)
                }
                return code;
            })
    },

    // 浮窗影片简讯
    FetchMovieSimpleFn({commit}, params){
        return home.fetchMovieSimple(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_CINEMA_TAG', data.info.tag)
                    commit('SET_CINEMA_URL', data.info.preview_url)
                }
                return code;
            })
    },

    // 获取首页banner数据
    FetchBannerListFn({commit}, params){
        return home.fetchBannerList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    data.list.sort(Utils.compare("sort"));
                    commit('SET_BANNER_LIST', data.list)
                }
                return code;
            })
    },

    // 获取套餐类型
    FetchComboListFn({commit}, params){
        return home.fetchComboList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    data.list.sort(Utils.compare("sort"));
                    commit('SET_COMBO_LIST', data.list)
                }
                return code;
            })
    },

    // 获取套餐支付方式
    FetchClientListFn({commit}, params){
      return home.fetchClientList(params)
        .then(res => {
          const { code, data } = res;
          if(code == 200){
            data.list.sort(Utils.compare("sort"));
            commit('SET_CLIENT_LIST', data.list)
          }
          return code;
        })
    },


    // 获取银行卡列表
    FetchBankCardListFn({commit}, params){
        return home.fetchBankCardList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_BANKCARD_LIST', data.list)
                }
                return code;
            })
    },

    // 开通会员
    PayMemberFn({commit}, params){
        return home.payMember(params)
            .then(res => {
                const { code, data } = res;

                return res;
            })
    },

    // 获取用户会员信息
    FetchUserMemberFn({commit}, params){
        return home.fetchUserMember(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_USERMEMBER_INFO', data.info)
                }
                return code;
            })
    },

    // 更换会员套餐
    SwitchMemcomboFn({commit}, params){
        return home.switchMemcombo(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    router.push("/member");
                }
                return code;
            })
    },

    // 获取栏目列表
    FetchBarListsFn({commit}, params){
        return home.fetchBarLists(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_BAR_LIST', data.list)
                }
                return code;
            })
    },

    // 获取栏目影片列表
    FetchBarMovieFn({commit}, params){
        return home.fetchBarMovie(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    return data.list;
                }
                return code;
            })
    },

    // 请求第三方登录接口
    FetchMentLoginFn({commit}, params){
        return home.fetchMentLogin(params)
            .then(res => {
                const { code, data} = res;
                return res;
            })
    },
    // H5首页影片推荐
    FetchMobHomeMovieFn({commit}, params){
        if(params.page == 1) commit('RESET_MOB_MLIST', []);
        return home.fetchMobHomeMovie(params)
            .then(res => {
                const { code, data } = res;
                if(data){
                    commit('SET_MOB_MOVIE_LIST', data.list)
                }else{
                    commit('SET_MOB_MOVIE_LIST', [])
                };

                return {code, data};
            })
    },
    // 兑换VIP
    FetchExchangeMemberFn({commit}, params){
        return home.fetchExchangeMember(params)
            .then(res => {
                const { code, data} = res;
                return res;
            })
    },
    // 获取广告配置
    FetchConfadlistFn({commit}, params){
      return home.fetchConfadlist(params)
        .then(res => {
          const { code, data} = res;
          if(data){
            commit('SET_CONFIGLIST', data.list)
          }else{
            commit('SET_CONFIGLIST', [])
          };
          return res;
        })
    },
    // 获取 aoflix 推荐相关
    FetchRecommendlistFn({commit}, params){
      return home.fetchrecommendlist(params)
        .then(res => {
          const { code, data} = res;
          if(data){
            commit('SET_RECOMMEND', data.list)
          }else{
            commit('SET_RECOMMEND', [])
          };
          return res;
        })
    },
    // 获取 aoflix 推荐相关
    FetchRecommendPagelistFn({commit}, params){
      return home.fetchrecommendpagelist(params)
        .then(res => {
          const { code, data} = res;
          if(data){
            if (data.list.Recently.length > 0){
              commit('SET_RECOMMENDRECENTLY', data.list.Recently)
            }else if (data.list.Featured.length > 0){
              commit('SET_RECOMMENDFEATURED', data.list.Featured)
            }
          }else{
            if (data.list.Recently){
              commit('SET_RECOMMENDRECENTLY',[])
            }else if (data.list.Featured){
              commit('SET_RECOMMENDFEATURED',[])
            }
          };
          return res;
        })
    },
    // 获取二维码下载
    FetchVerisonUpdateFn({commit}, params){
      return home.fetchversionupdate(params)
        .then(res => {
          const { code, data} = res;
          if(data){
            commit('SET_VERISION', data.info)
          }else{
            commit('SET_VERISION', [])
          };
          return res;
        })
    },
};

export default {
    state,
    mutations,
    actions
}
