<template>
    <section class="header1">
        <topAdlist :sendconfigList="sendconfigList" v-if="sendconfigList  && device == 'mob'"></topAdlist>
        <div class="container ">
            <div class="header_left">
              <img class="logo " :src="device=='web'? require(`@/assets/images/common/logo.png`) : require(`@/assets/images/common/logo-h5.png`)"
                   @click="handleGoHome()"
              />
                <nav v-if="menuinfo && menuinfo.info && avnav" class="nav_ctn">
                    <a v-for = "(item, index) in menuinfo.info.PC" v-if="menuinfo.info.PC && menuinfo.info.PC.length>0" :class="item.type==5 ? 'red':''"
                       :key="index" @click="routerTo(item.type)">
                      {{ item.name }}
                    </a>
                </nav>
            </div>
            <div class="header_right">
                <div class="searchbox">
                    <el-input
                        :placeholder="$t('header.1')"
                        prefix-icon="el-icon-search"
                        v-model="input2"
                        @input="searchPcInput"
                        :autofocus="true"
                        ref="inputSty"
                        >
                    </el-input>
                    <!-- <span class="i-search" @click="searchPcHandler">{{ $t('header.2') }}</span> -->
                </div>
                <!-- 手机端搜索 -->
                <div class="searchH5" v-if="device =='mob'">
                    <img src="@/assets/images/avatar/Search.png" @click="handSearchH5()" alt=""/>
                    <img src="@/assets/images/avatar/download.png" @click="downloadQR()" v-if="appSource() != 'ios'" alt=""/>
                </div>
                <div class="sign_ctn" @mouseenter="handHover()" @mouseleave="handOut()" >
                    <div class="tt"></div>
                    <!-- 头像 -->
                    <a v-if="token" class="avatar " href="javascript:void(0);" @click="handSignH5()"
                    >
                        <img :src="require(`@/assets/images/avatar/avatar_df.png`)" />
                        <i :class="hoverVal ? 'active':''"></i>
                    </a>
                    <ul v-else class="sign-box" @click="SetSiginStatusFn(true),SetSigupStatusFn(false),removePath()">
                        <li>{{ $t('header.Login') }}</li>
                    </ul>
                    <div class="sign_hover" v-if="token && device =='web'">
                        <div class="link-line">
                            <span class="text" @click="$router.push('/member')">{{$t('header.changePassword')}}</span>
                        </div>
                       <div class="line"></div>
                        <div class="link-line">
                          <span class="text" @click="$router.push('/mine/HistoryRecord')">{{$t('mine.10')}}</span>
                        </div>
                       <div class="line"></div>
                        <div class="link-line">
                          <span class="text" @click="$router.push('/mine/BillingDetails')">{{$t('mine.24')}}</span>
                        </div>
                        <div class="line"></div>
                        <el-button type="info" @click="SetSignOut(false)">{{$t('header.signout')}}</el-button>
                    </div>
                </div>
                <div class="download_btn" v-if="device =='web'">
                  <div class="down_btn" @click="switchDown()">Download the app<i></i></div>
                  <div class="down_show" v-show="downshow">
                    <div class="qrcode">
                      <vue-qr ref="qrCode" :correctLevel="3" :autoColor="false" colorLight="#fff" colorDark="#000" :text="downqrcode" :size="178" :margin="12" :logoMargin="3" :whiteMargin="true"></vue-qr>
                    </div>
                  </div>
                </div>
            </div>
            <div class="sign-Bg" v-if="siginStatus || sigupStatus || passwordStatus || bdemlStatus"></div>
            <transition
                enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown"
            >
                <Password v-if="passwordStatus" style="animation-duration: 400ms;"></Password>
            </transition>
            <transition
                enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown"
            >
              <sign-up v-if="sigupStatus" style="animation-duration: 400ms;"></sign-up>
            </transition>
            <transition
                enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown"
            >
             <sign-in v-if="siginStatus" style="animation-duration: 400ms;"></sign-in>
            </transition>
            <transition
                enter-active-class="animated fadeIn" leave-active-class="animated fadeOut"
            >
                <vip-box v-if="vipStatus"></vip-box>
            </transition>
            <transition
                enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown"
            >
                <bind-email v-if="bdemlStatus" style="animation-duration: 400ms;"></bind-email>
            </transition>
        </div>
        <!-- h5 导航栏 -->
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div class="navH5_bj" v-if="navH5Active" style="animation-duration: 400ms;" @click="handlH5Show()">
                <ul class="navH5"  v-if="menuinfo && menuinfo.info" >
                    <li v-for = "(item, index) in tabs" :key="index"  @click="testurl(item.routerUrl)" v-if="menuinfo.info.PC && menuinfo.info.PC.length>0">
                    <span>
                        {{ item.text }}
                    </span>
                    </li>
                </ul>
            </div>
        </transition>
        <!-- h5 个人中心入口 -->
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div class="sign_hover_H5bj" v-if="signH5Active" style="animation-duration: 400ms;">
                <ul class="navH5">
                    <li>
                        <span @click.stop="goMine(1)">
                            {{$t('header.changePassword')}}
                        </span>
                    </li>
                    <li>
                          <span @click.stop="goMine(2)">
                              {{$t('mine.10')}}
                          </span>
                    </li>
                    <li>
                          <span @click.stop="goMine(3)">
                              {{$t('mine.24')}}
                          </span>
                    </li>
                    <li>
                        <el-button type="info" @click="handSigout()">{{$t('header.signout')}}</el-button>
                    </li>
                </ul>
            </div>
        </transition>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Password from "@/components/Common/Password";
import SignIn from "@/components/Common/SignIn";
import SignUp from "@/components/Common/SignUp";
import BindEmail from "@/components/Common/BindEmail";
import VipBox from "@/components/Common/VipBox";
import topAdlist from "@/components/Home/TopAd.vue"
import './css/WjsHeader.css'
import vueQr from 'vue-qr'
export default {
    name: "WjsHeader",
    data(){
      return {
        tabs: [
          { text: this.$t('header.Home'), routerUrl:'/'},
          { text: this.$t('header.AllMovies'), routerUrl:'/allmovies'}, // 全部影片
          // { text: this.$t('header.Catalog'), routerUrl:'/Catalog'}, //  电视剧
          { text: this.$t('header.CastList'), routerUrl:'/castlist'},  // 演员列表
          { text: this.$t('header.exclusive'), routerUrl:'/exclusive'}, // EXCLUSIVE
        ],
        options: [
            {
                value: '1',
                label: 'ENGLISH',
            },
            {
                value: '2',
                label: '简体中文',
            },
        ],
        options2: [
            {
                value: '1',
                label: 'En',
            },
            {
                value: '2',
                label: '中文',
            },
            // {
            //     value: '3',
            //     label: '日本語',
            // }
        ],
        value: this.$t('header.3'),
        value2: this.$t('header.03'),
        input2:'',
        hoverVal:false,
        navH5Active: false,
        isOpenMask: true,
        signH5Active: false,
        sendconfigList:[],
        downshow:false,
        downqrcode: "",
      }
    },
    props: {
        avnav: { type: Boolean, default: true},
        configList: { type: Array },
    },
    computed: {
        ...mapState({
            passwordStatus: (state) => state.account.passwordStatus,
            siginStatus: (state) => state.account.siginStatus,
            sigupStatus: (state) => state.account.sigupStatus,
            vipStatus: (state) => state.account.vipStatus,
            token: (state) => state.account.token,
            avatar: (state) => state.account.avatar,
            mine: (state) => state.account.mine,
            bdemlStatus: (state) => state.account.bdemlStatus,
            versionupdate: (state) => state.home.versionupdate,
            menuinfo: (state) => state.account.menuinfo
        }),
    },
    async mounted() {
        const { keywords } = this.$route.query;
        this.input2 = keywords;
        const result = await this.FetchVerisonUpdateFn();
        const result1 = await this.getNavinfoFn( {size: 3} );
        if(this.token){
            this.FetchUserInfoFn();
        }
        this.$nextTick(() => {
            if(this.device =='web'){
                this.$refs.inputSty.focus();
            }
            this.downqrcode = this.versionupdate.down_url
        })
    },
    methods: {
        ...mapActions({
            SetSiginStatusFn: "SetSiginStatusFn",
            SetSigupStatusFn: "SetSigupStatusFn",
            SetPasswordStatusFn:"SetPasswordStatusFn",
            SetSignOut:"SetSignOut",
            FetchUserInfoFn: "FetchUserInfoFn",
            SetBdemlStatusFn: "SetBdemlStatusFn",
            FetchVerisonUpdateFn: "FetchVerisonUpdateFn",
            getNavinfoFn: "getNavinfoFn",
        }),
        routerTo(type) {
          switch (type) {
            case 1:
              type = "/"
              break;
            case 2:
              type = "allmovies"
              break;
            case 3:
              type = "castlist"
              break;
            case 4:
              type = "exclusive"
              break;
            case 5:
              type = "short"
              break;
          }
          if (type == 6){
            if (this.token){
              window.open(process.env.ENV_BASEURL+'/categories?tokens='+this.token);
            }else{
              window.open(process.env.ENV_BASEURL+'/categories');
            }
          }else{
            this.$router.push({
              path: '/'+type,
            });
          }
        },
        downloadQR() {
          gtag("event", "ct_click_downloadbutton");
          const a = document.createElement('a')
          // 下载的文件名
          a.download = 'scanImage'
          // url
          a.href = this.downqrcode
          // 触发点击
          a.click()
        },
        appSource() {
          const u = navigator.userAgent;
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
          if (isiOS) {
            return "ios";
          } else {
            return "andriod";
          }
        },
        switchDown(){
           this.downshow==true? this.downshow = false:this.downshow=true
        },
        removePath(){
          window.localStorage.removeItem('path');
        },
        visibleChange(val) {
          this.$emit("visibleChange", val);
        },
        testurl(type){
          this.$router.push(type).catch(err => {err});
        },
        handHover(){
            this.hoverVal = true;
        },
        handOut() {
            this.hoverVal = false;
        },
        searchPcInput(){
            if (this.input2) {
                this.$router.push({
                    path: "/search",
                    query: {
                        keywords: this.input2,
                    },
                });
                // 开关
                if(this.isOpenMask){
                        this.isOpenMask = false;
                        this.timer = setTimeout(() => {
                            this.$emit('searchPcHandler2', this.input2);
                            this.isOpenMask = true;
                        }, 1000);
                    }

            } else {
                // this.$message({
                //     message: this.$t('header.search'),
                //     type: "warning",
                // });
                let curr = localStorage.getItem('preRoute')
                this.$router.push(curr);
            }
        },
        handDowH5 () {
            if( !this.navH5Active ){
                this.navH5Active = true;
            }else{
                this.navH5Active = false;
            }
            this.signH5Active = false;
        },
        handSearchH5() {
            this.$router.push("/search")
        },
        handSignH5() {
            if (this.device =='mob' && this.token) {
                if( !this.signH5Active){
                    this.signH5Active = true;
                }else{
                    this.signH5Active = false;
                }
                this.navH5Active = false;
            }
        },
        handlH5Show() {
            if(this.device =='mob') {
                this.navH5Active = false;
            }

        },
        handSigout() {
            this.SetSignOut(false),
            this.signH5Active = false;
        },
        handleGoHome(){
            this.$router.push('/');
            // location.reload()
        },
        goMine(num) {
            if (num==1){
              this.$router.push('/member');
            }else if(num==2){
              this.$router.push('/mine/HistoryRecord');
            }else{
              this.$router.push('/mine/BillingDetails');
            }

            this.signH5Active = false;
        }


    },
    watch: {
        $route: {
            immediate:true,
            handler(to, from){
              // console.log('to',to)
              // console.log('22',this.$route)
            }
        },
        token(){
            // token过期
            if( !this.token ){
                this.SetSignOut();
                // location.reload();
            }
        },
        configList() {
          this.sendconfigList = this.configList
        },
        deep:true

    },
    components: {
        SignIn,
        SignUp,
        VipBox,
        Password,
        BindEmail,
        topAdlist,
        vueQr
    },
}
</script>
