import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n)
import en from './config/en';
import cn from './config/cn';
import jp from './config/jp';


const i18n = new VueI18n({
    locale: window.localStorage.getItem("locale") || '1',
    messages:{
        '1': en,
        '2': cn,
        '3': jp,
    }
})
export default i18n;
