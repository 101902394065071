import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixin from "./mixin";
import axios from './utils/interceptor'
import i18n from './assets/i18n/';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import Swiper from 'swiper'
import '@/assets/css/swiper.css'
import {Utils} from './utils/utils.js'
import '@/assets/css/common.css?v=1.10'
import animated from 'animate.css'
import VueLazyLoad from 'vue-lazyload'



Vue.use(VueLazyLoad,{
    preLoad:1.3,
    loading: require('./assets/images/movies/loading6682.gif'),
    error: require('./assets/images/movies/Default_cover.png'),
    attempt:2,
  });
Vue.use(ElementUI);
Vue.use(mixin)
Vue.prototype.$axios = axios;
Vue.prototype.$video = Video;
Vue.prototype.$swiper = Swiper;
Vue.prototype.$utils = Utils;
Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
